@tailwind base;
@import "~aos/dist/aos.css";
@import "@fontsource/dm-sans/index.css";
@import "@fontsource/roboto";
@import "react-virtualized/styles.css";
@import "rc-slider/assets/index.css";
@import "./variables.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@tailwind components;

@tailwind utilities;

*,
*:before,
*:after {
  box-sizing: inherit;
}

@keyframes progress-grow {
  0% {
    width: 0;
  }
}

html {
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  font-feature-settings: "case" 1, "rlig" 1, "calt" 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-y: auto;*/
  overflow: initial !important;
}
/* html[style*="overflow"] {
  overflow-y: auto !important;
} */
html,
body {
  font-family: (--var-font-family);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary);
  color: var(--text-primary);
}
.min-h-screen {
  overflow-y: auto;
}
body {
  position: relative;
  margin: 0;
  min-height: 100%;
  overflow-y: auto !important;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button:focus {
  outline: none;
}

input:focus {
  box-shadow: none;
  outline: none;
}

.striped-background {
  background: repeating-linear-gradient(45deg, #161522, #161522 10px, #0d0415 10px, #0d0415 20px);
}

.highcharts-xaxis-grid .highcharts-grid-line {
  stroke-width: 1px;
  stroke: #828991;
  stroke-dasharray: 3;
}



#subtitle {
  background-color: #0b1221;
  border-radius: 15px;
  margin: 10px;
  padding: 8px;
}

#subtitle-text {
  /* border: 2px solid black; */
  /* border-radius: 15px; */
  /* background-color: #0B1221; */
  /* margin: 10px; */
  /* padding: 8px; */

  background: linear-gradient(to right, #3d5dff 0%, #ff73e1 100%);
  background-clip: text;
  color:transparent;
  -webkit-background-clip: text;
  -webkit-color: transparent;
  /* font-size: 12px; */
}

/**::-webkit-scrollbar {*/
/*  width: var(--scrollbar-width);*/
/*}*/

/*::-webkit-scrollbar-track-piece:start {*/
/*  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAQAAAAD+Fb1AAAADklEQVR42mNkgAJG3AwAAH4ABWjFc8IAAAAASUVORK5CYII=) repeat-y !important;*/
/*}*/

/*::-webkit-scrollbar-track-piece:end {*/
/*  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAQAAAAD+Fb1AAAADklEQVR42mNkgAJG3AwAAH4ABWjFc8IAAAAASUVORK5CYII=) repeat-y !important;*/
/*}*/

/**::-webkit-scrollbar-thumb {*/
/*  border: 3px solid rgba(0, 0, 0, 0);*/
/*  background-clip: padding-box;*/
/*  border-radius: 9999px;*/
/*  background-color: #BFBFBF;*/
/*}*/

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  background-image: url("../../public/images/datepicker-icon.svg");
}

/* if backdrop support: very transparent and blurred */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdrop-blur-fallback {
    background-color: var(--primary);
  }
}



.gradient-button {
  position: relative;
  background: linear-gradient(150deg, #3551dd 0%, #02dab2 100%);
  border-radius: 10px;
  width: 100%;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.gradient-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.gradient-button:disabled:hover::before {
  opacity: 0;
}

.gradient-button::before {
  content: "";
  position: absolute;
  border-radius: 10px;
  border: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  background: linear-gradient(-150deg, #3551dd 0%, #02dab2 100%);
  z-index: -1;
}

.gradient-button:hover::before {
  opacity: 1;
}

#auction-card {
  background: linear-gradient(rgb(19, 25, 40) 0px, rgb(19, 25, 40) 0px) padding-box padding-box, linear-gradient(135deg, #02dab2 0%, #3551dd 100%) border-box border-box;
  border: 2px solid transparent;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}

.auction-logo {
  position: absolute;
  top: 50% !important;
  width: 100px;
  height: 100px;
  border: 2px solid #fff;
}

.stepHiddenLeft {
  position: absolute;
  z-index: -1;
  height: 0;
  transform: translateX(-100%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.stepHiddenRight {
  position: absolute;
  z-index: -1;
  height: 0;
  width: 0;
  transform: translateX(200%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.stepVisible {
  background: linear-gradient(rgb(19, 25, 40) 0px, rgb(19, 25, 40) 0px) padding-box padding-box, linear-gradient(135deg, #02dab2 0%, #3551dd 100%) border-box border-box;
  border: 2px solid transparent;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width: 100%;
  padding: 40px;
  height: max-content;
  display: block;
  transform: translateX(0%);
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.checked-box-token, .unchecked-box-token {
  width: 100%;
  padding: 60px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
}

.checked-box, .unchecked-box {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  cursor: pointer;
  border-radius: 10px;
}

.checked-box div, .unchecked-box div {
  margin-bottom:10px;
}

.checked-box-token, .checked-box {
  background: linear-gradient(#23293b 0px, #23293b 0px) padding-box padding-box, linear-gradient(135deg, #02dab2 0%, #3551dd 100%) border-box border-box;
  border: 2px solid transparent;
  color: white;
  border-radius: 10px;
}


.unchecked-box-token, .unchecked-box {
  background: #1b202e;
  border: 2px solid transparent;
}

.unchecked-box-token:hover, .unchecked-box:hover {
  background: #23293b;
}

.token-progress {
  background: linear-gradient(135deg, rgb(61, 93, 255) 0%, rgb(90, 249, 196) 100%);
  border-radius: 9999px;
  transition: width 0.3s ease-in-out;
  animation: progress-grow 4s ease-in-out;
  height: 10px;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.bannerCurrency {
  border-radius: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: -65px;
}

.bannerInput {
  margin-bottom: -20px;
}

/*
.footerSpacing{
  padding-left: 16%;
} */




.connect-button {
  position: relative;
  background: linear-gradient(150deg, #ffd148 0%, #ff73e1 33%, #3d5dff 66%, #00ffcf 100%) !important;;
  border-radius: 10px;
  width: 100%;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.connect-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.connect-button:disabled:hover::before {
  opacity: 0;
}

.connect-button::before {
  content: "";
  position: absolute;
  border-radius: 10px;
  border: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  background: linear-gradient(-150deg, #ffd148 0%, #ff73e1 33%, #3d5dff 66%, #00ffcf 100%) !important;
  z-index: -1;
}

.connect-button:hover::before {
  opacity: 1;
}

.Button {
  position: relative;
  /* background-color: #00ffcf; */
  background: linear-gradient(150deg, #3551dd 0%, #02dab2 100%) !important;
  padding-left: 0;
  padding-right: 0;
}

.Button:hover {
  /* background-color: #00ffcf !important; */
  background: linear-gradient(150deg, #3551dd 0%, #02dab2 100%) !important;
}

.farmHeader {
  width: 77%;
  margin-left: 19.5%;
}

/* .farmMain {
  width: 77%;
  margin-left: 23%;
} */

@media only screen and (max-width: 1023px) {
  .farmHeader {
    width: 100%;
    margin-left: 0%;
    padding: 0px;
  }

  .farmMain {
    width: 100%;
    margin-left: 0%;
    padding: 15px;
  }
}

.portfolio {
  width: 100%;
}



.fullNav {
  height: 100%;
}

/* .languageButton{
  margin-top: -150px;
} */
.top-margin{
  margin-top: 40px;
}
.footerMain {
  width: 100%;
  padding-left: 330px;
  margin-top: 200px;
}
@media screen and (max-width: 1700px) {
  .footerMain {
    padding-left: 260px;
  }
}
.dropButton {
  background: #1d2231;
}

.bannerCard {
  border-radius: 5px !important;
}

.farmItem_grid {
  margin-bottom: 0%;
  border: 3px solid #0b1221;
  border-radius: 12px;
  background: #131928;
  margin-left: 5%;
  margin-bottom: 8%;
}

.farmItem {
  background: #131928;
  margin-bottom: 0.75%;
}

.farmItemF {
  background: #131928;
  margin-bottom: 1.5rem;
  min-height: 70px;
}

.switch-button {
  background: #1d2231;
  border-radius: 35px;
  overflow: hidden;
  width: 540px;
  text-align: center;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.36px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #ffffff;
  position: relative;
  padding-right: 120px;
  position: relative;
  height: 54px;
  justify-content: space-between;
}

.switch-button:before {
  content: "My Farms";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
  /* color: rgba(255, 255, 255, 0.3); */
  color: #ffffff;
}

.switch-button-checkbox {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}

.switch-button-checkbox:checked + .switch-button-label:before {
  transform: translateX(120px);
  transition: transform 300ms linear;
}

.switch-button-checkbox + .switch-button-label {
  position: relative;
  padding: 15px 0;
  display: block;
  height: 54px;

  user-select: none;
  pointer-events: none;
}

.switch-button-checkbox + .switch-button-label:before {
  content: "";
  background: #292d3cff;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 30px;
  transform: translateX(0);
  transition: transform 300ms;
}

.switch-button-checkbox + .switch-button-label .switch-button-label-span {
  position: relative;
}

.farmItem_1 {
  padding-right: 31%;
}

.farmItem_2 {
  padding-left: 6%;
}

.farmItem_3 {
  padding-right: 12%;
  margin-top: -12%;
}

.farmItem_4 {
  padding-right: 45%;
}

.TLV {
  padding-left: 7%;
}

.reward {
  padding-right: 29%;
}

.navElement {
  border: 2px solid transparent;
  border-image: linear-gradient(135deg, #5af9c4 0%, #3d5dff 50%, #ff73e1 100%) 1;
  border-radius: 10px;
  border-style: solid;
  border-collapse: separate;
}

.bannerInput::placeholder {
  color: #ffffff;
  font-size: 18px;
  opacity: 1;
  /* Firefox */
}

.cardBorder {
  border: 2px solid transparent;
  border-image: -webkit-linear-gradient(135deg, #5af9c4 0%, #3d5dff 50%, #ff73e1 100%) 1;
  border-radius: 10px;
  border-style: solid;
  background: #131928 0% 0% no-repeat padding-box;
}

/* .headerGrid{
  margin-left: -39%;
} */
.tow_btn {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
}

.tow_btn2 {
  display: flex;
  align-items: center;
  width: 420px;
  justify-content: flex-start;
}


.gridBtn {
  padding-right: 20px;
}

.pading_lr {
  width: 100%;
  padding-left: 300px;
  max-width: 100%;
  padding-right: 40px;
}
.pading_lrF {
  width: 100%;
  max-width: 100%;
  padding-right: 40px;
}


/* responsive  */
@media only screen and (max-width: 1023px) {
  .pading_lr {
    padding-left: 40px;
  }
  .pading_lrF {
    padding-left: 40px;
  }
}



@media only screen and (max-width: 600px) {
  .footerMain {
    width: 100%;
    margin-left: 0%;
  }
  #subtitle {
    background-color: #0b1221;
    border-radius: 15px;
    margin: 5px;
    padding: 3px;
    /* position: absolute; */
    /* left: 29px; */
    /* top: 42%; */
  }
  .layout-modal {
    width: 100%;
  }

  .siple_grid_bx .grid_colms .color_bagrd {
    max-width: none !important;
  }

  .pading_lr {
    padding-left: 15px;
    padding-right: 15px;
  }
  .pading_lrF {
    padding-left: 15px;
    padding-right: 15px;
  }

  .switch-button {
    font-size: 16px;
    line-height: 22px;
  }
}

@media only screen and (max-width: 600px) {
  .farmMain {
    width: 100%;
    margin-left: 0%;
  }
}

@media only screen and (max-width: 600px) {
  .headerGrid {
    margin-left: 0%;
  }
}

.swapWord {
  background-color: #292d3c;
  border: none;
  color: #ffffff;
  padding: 9px 26px 9px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  border-radius: 26px;
  display: flex;
  align-items: center;
}

.rotate {
  transform: rotate(90deg);
}

.rotateArrowDown {
  transform: rotate(90deg);
}

.rotateArrowUp {
  transform: rotate(270deg);
}

.logoDiv {
  /* margin-left: auto; */
  width: 50%;
  /* display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden */
}

.image-container {
  width: 100%;
}

.image-container .image {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}

.image-container > div {
  position: unset !important;
}

#add-liquidity-page {
  margin-left: 26%;
}

#miso-page {
  margin-left: 26%;
}

.poolAddCard {
  /* width: 65% !important; */
  background-color: #131928 !important;
  border-radius: 10px;
}

@media only screen and (max-width: 1023px) {
  #add-liquidity-page {
    margin-left: 0%;
    padding: 10px;
  }

  #miso-page {
    margin-left: 0%;
    padding: 10px;
  }

  .poolAddCard {
    width: 100% !important;
    background-color: #131928 !important;
  }
}

.poolAlert {
  /* width: 65%; */
  background-color: #1d2231;
  position: relative;
  padding: 20px 40px;
}

.poolAlert > div > div {
  font-weight: normal;
  font-size: 13px;
  line-height: 23px;
  color: rgba(255, 255, 255, 0.4);
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.48px;
}

.poolAlert button {
  position: absolute;
  right: 7px;
  top: 7px;
}

.poolImportAddCard {
  margin-left: 23%;
}

@media only screen and (max-width: 1023px) {
  .poolImportAddCard {
    margin-left: 0%;
    padding: 15px;
  }

  .poolAlert {
    width: 100%;
    background-color: #1d2231;
  }
}

.poolAddInput {
  padding-left: 1%;
  padding-top: 1%;
  color: #c0c0c0;
}

.poolAddCurrency {
  padding-bottom: 3%;
}

.maxButton {
  background-color: #292d3c;
  border: none;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 0px;
  font-size: 10px;
}

.poolBalance {
  margin-top: -4%;
  color: #c0c0c0;
}

.poolPlaceholder::placeholder {
  color: #ffffff;
}

.poolPlaceholder {
  background-color: #161522;
  letter-spacing: 0.48px;
  font-weight: normal;
  font-size: 24px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  padding-left: 18px;
}

.ArrowIcon {
  color: #ffffff !important;
}

.modalCurrencyName {
  font-size: 10px;
}

.pair {
  color: #ffffff;
  font-weight: 600;
}

.Approve {
  height: 48px;
  font-size: 16px;
}

.autoBtn {
  color: #ffffff;
  border: 1px solid #ffffff;
}

.poolBrowseAlert {
  width: 65%;
  background-color: #131928;
}

.poolBrowseBtn {
  width: 65%;
}

.poolBrowser {
  margin-left: 26%;
}

.poolManage {
  width: 65%;
}

.poolRemove {
  width: 100%;
  margin-left: 26%;
}

.stake {
  width: 65%;
  margin-left: 26%;
}

@media only screen and (max-width: 1023px) {
  .poolBrowser {
    margin-left: 0%;
    padding: 15px;
  }

  .poolBrowseAlert {
    width: 100%;
    background-color: #131928;
  }

  .poolBrowseBtn {
    width: 100%;
  }

  .poolManage {
    width: 100%;
  }

  .poolRemove {
    width: 100%;
    margin-left: 0%;
    padding: 15px;
  }

  .stake {
    width: 100%;
    margin-left: 0%;
    padding: 15px;
  }
}

.inputPoolRemove {
  width: 40%;
}

.Button1 {
  /* background-color: #00ffcf; */
  background-image: linear-gradient(150deg, #00ffcf 0%, #3d5dff 33%, #ff73e1 66%, #ffd148 100%) !important;
}

.Button2{
  background-image: linear-gradient(150deg, #ff75de 0%,  #ffce4c 100%) !important;
}

.uncheckedBtn {
  color: #ffffff;
}

.mn_logo {
  margin-bottom: 55px;
  cursor: pointer;
}

.cstmmn {
  padding: 7px 25px 7px 7px;
  margin: 10px 0 5px 0;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}

.cstmmn:hover {
  color: #fff;
}

.cstmmn span {
  margin-right: 15px;
}

.cstmmn svg {
  margin-left: auto;
  color: #636a7a;
  width: 13px;
}

.cstmmn:hover span img {
  color: #fff;
  filter: invert(100%) brightness(10);
}

@media screen and (max-width: 1023px) {
  .cstmmn {
    padding: 7px 25px 7px 0px;
  }
}

.mnlst {
  color: #636a7a;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
}
.text-white .rwrd_ic_ic path {
  stroke: #fff;
}
/* .mnlst[aria-expanded="true"] {
  color: #fff;
} */

/* .mnlst[aria-expanded="true"] span img {
  filter: invert(100%) brightness(10);
} */

.mnlst svg {
  width: 18px;
  height: 18px;
}

.mninitm {
  position: relative;
  padding: 3px 0 0 34px;
  margin: 10px 0;
  background: transparent !important;
  color: #636a7a;
}

.mninitm:hover::before,
.mninitm.active::before {
  content: "";
  position: absolute;
  right: 0;
  top: 5px;
  bottom: 0;
  width: 1px;
  background: #fff;
  height: 16px;
}

.mainlst {
  position: relative;
  padding-bottom: 1px;
  margin-left: 8px;
}

.mainlst::before {
  content: "";
  position: absolute;
  left: -2px;
  top: 8px;
  bottom: 8px;
  width: 3px;
  background: #292d3c;
}

.cstm_cntnr {
  padding-left: 260px;
  max-width: inherit;
  margin-left: 0 !important;
}

.cstm_cntnr_pools {
  padding-left: 260px;
  max-width: 1200px;
  /* margin-left: 0 !important; */
}

.poolpage_prnt {
  max-width: 700px;
  /* margin: 0 auto; */
}

.poolpage_prnt_import {
  max-width: 700px;
  /* margin: 50px auto; */
}

.poolpage_import {
  padding: 45px 30px;
}

.img_petrol {
  display: flex;
  align-items: center;
  margin-right: 25px;
}

.img_petrol p {
  font-weight: medium;
  font-size: 16px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}

.img_petrol img {
  margin-right: 11px;
}

.man_box_paddig {
  padding: 50px 40px 35px 40px;
}

.input_p {
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  letter-spacing: 0.3px;
}

.box_inr_pading {
  padding: 16px 20px;
}

.balnc_p {
  text-align: right;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  letter-spacing: 0.3px;
  margin-bottom: 5px;
}

.margin_b {
  margin-bottom: 13px;
}

.btn_plus {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -24px;
  margin-top: -9px;
  border-radius: 4px;
  margin-left: 5px;
}

.input_hight {
  max-width: 268px;
  height: 55px;
  background-color: rgba(19, 25, 40, 1) !important;
}

.input_hight input {
  background-color: rgba(19, 25, 40, 1) !important;
}

.border_r {
  border-radius: 10px;
  margin-bottom: 30px;
}

.selec_p {
  font-weight: medium;
  font-size: 24px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.48px;
}

@media screen and (max-width: 1023px) {
  .man_box_paddig {
    padding: 50px 0px 35px 0px;
  }
}

@media screen and (max-width: 575px) {
  .selec_p {
    font-size: 17px;
  }

  .man_box_paddig {
    padding: 0px 0px 25px 0px;
  }

  .poolAlert {
    padding: 11px 15px;
  }
}

.mx-f100 {
  max-width: 100%;
}

.border_top {
  border-top: 2px solid rgba(255, 255, 255, 0.13);
}

.footer_p {
  letter-spacing: 0.32px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 600;
  font-size: 15px;
  line-height: 32px;
  font-family: "Poppins", sans-serif;
}
.footer_p_frst {
  line-height: 22px;
  max-width: 343px;
}
.foter_h1 {
  letter-spacing: 0.32px;
  color: #ffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  font-family: "Poppins", sans-serif;
  margin-top: 0;
  margin-bottom: 15px;
}
.foter_links {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 15px;
}
.scal_link_prnt {
  margin-top: 15px;
}
@media screen and (max-width: 1023px) {
  .footerMain {
    padding-left: 0;
    margin-top: 100px;
  }

  .border_top {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 575px) {
  .footerMain {
    margin-top: 40px;
  }
}

.confirmSwap {
  flex-direction: row;
  justify-content: center;
}

@media only screen and (max-width: 512px) {
  .confirmSwap {
    flex-direction: column;
  }
}

.z-9999 {
  z-index: 999;
}

.z-9999 {
  z-index: 9999;
}

.frslctarrw {
  position: relative;
}

.frslctarrw svg {
  position: absolute;
  right: 2px;
  top: 0px;
}

.transition {
  transition: all 0.2s ease-out !important;
}

.border_main_bx {
  padding: 15px 22px 30px 22px;
}

.border_main_bx .space_text_end {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.border_main_bx .space_text_end p {
  font-family: "DM Sans";
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
}

.siple_grid_bx {
  display: grid;
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.5rem;
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.siple_grid_bx .grid_colms {
  grid-column: span 3 / span 3;
}

.siple_grid_bx .grid_colms .color_bagrd {
  background: #0b1221;
  border-radius: 16px;
  max-width: 258px;
  min-height: 65px;
  padding: 12px 0 10px 18px;
}

.siple_grid_bx .grid_colms .color_bagrd p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12.142px;
  line-height: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: #ffffff;
}

.siple_grid_bx .grid_colms .color_bagrd h4 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.siple_grid_bx_marg {
  margin-top: 16px;
  margin-bottom: 13px;
}

.text_radio_custm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 38px;
  margin-bottom: 10px;
}

.text_radio_custm .custm_text_bx {
  display: flex;
}

.text_radio_custm .custm_text_bx h5 {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding-left: 19px;
}

.text_radio_custm .custm_text_bx .gren_text {
  width: 94px;
  height: 25px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12.142px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #940c6d;
  margin-left: 10px;
  background: #200017;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio_btn button {
  padding: 0;
  width: 44px;
  height: 28px;
}

@media screen and (max-width: 767px) {
  .stepVisible {
    padding: 20px;
  }

  .siple_grid_bx .grid_colms {
    grid-column: span 6 / span 6;
  }

  .radio_btn button {
    padding: 0;
    width: 36px;
    height: 26px;
  }

  .text_radio_custm .custm_text_bx h5 {
    font-size: 16px;
    padding-left: 0px;
  }

  .text_radio_custm .custm_text_bx .gren_text {
    width: 80px;
    margin-left: 6px;
  }
}

@media only screen and (min-width: 768px) {
  .layout-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 575px) {
  .border_main_bx {
    padding: 10px 10px 20px 10px;
    margin: 20px;
  }

  /* .siple_grid_bx .grid_colms {
    grid-column: span 12/span 12;
  } */

  .text_radio_custm {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .text_radio_custm .custm_text_bx {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  .text_radio_custm .custm_text_bx .gren_text {
    margin-left: 0px;
    margin-top: 8px;
  }

  .siple_grid_bx .grid_colms .color_bagrd h4 {
    font-size: 17px;
  }
  .siple_grid_bx .grid_colms .color_bagrd {
    min-height: 55px;
    padding: 7px 0 6px 10px;
  }
}
.layout-modal {
  overflow-y: auto;
  max-height: 100vh;
}
.bwcp_btn {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}
.zinx100000 {
  z-index: 10000000 !important;
}
.zinx100000:before {
  content: "";
  /* position: fixed; */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  /* background: rgb(0, 0, 0, 0.8); */
}
.min_prnt_bg .port_bg_color {
  background: #131929 !important;
}
.min_prnt_bg .port_bg_color .bg-chevron {
  display: none;
}
.now_woeth_as .font-bold {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #FFFFFF;
  margin-bottom: 3px;
}
.now_woeth_as .leading-6 {
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
}

.pool_browse {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  margin-top: 3px;
}

/* .padi_lf_ab{
  padding-left: 80px;
}
@media screen and (max-width: 991px) {
  .padi_lf_ab{
    padding-left: 0px;
    margin-top: 25px;
  }
} */

/* nls start */

.nls_mr_btmm {
  margin-top: 28px;
}
.nls_bx_burned {
  background: #131929;
  padding: 34px 25px 57px 20px;
  height: 234px;
  margin-top: 37px;
  margin-left: 12px;
}
.burned_bx_nls .leading-5 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 29px;
  color: #ffffff;
}
.spop_burn_nls_main {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.two_txt_mx_nls {
  width: 77%;
  margin-left: 15px;
}
.txt_211_87_nls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 9px;
}
.txt_211_87_nls .leading-5 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
  min-width: 70px;
}
.spop_usd_nls_txt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.spop_usd_nls_txt .leading-5 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #a3a3a3;
  min-width: 70px;
}
.def_lpddng {
  margin-top: 68px;
}
@media screen and (max-width: 1024px) {
  .nls_bx_burned {
    width: 100%;
    margin-top: 0;
  }
}

.def_lpddng {
  padding-left: 260px;
}
.nls_prnt_bg {
  max-width: 1260px;
  margin: 0 auto !important;
  width: 100% !important;
}
.cstm_prntclss {
  max-width: 1260px;
  margin: 0 auto !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: initial !important;
  padding: 0 !important;
  position: relative;
}
.nls_mx_wdth_1 {
  width: 65%;
}
.nls_mx_wdth_2 {
  width: 35%;
}
@media screen and (max-width: 1440px) {
  .cstm_prntclss {
    flex-direction: column !important;
    width: 100% !important;
  }
  .nls_mx_wdth_1 {
    width: 100%;
    margin: 0 auto;
  }
  .nls_mx_wdth_2 {
    width: 100%;
    margin: 0 auto;
  }
  .def_lpddng {
    margin-top: 22px;
  }
}
@media screen and (max-width: 1023px) {
  .def_lpddng {
    padding-left: 0;
  }
}
.grid_cod_raw {
  display: grid;
  grid-row-gap: var(--chakra-space-4);
  grid-column-gap: 40px;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  justify-content: center;
}
.girid_col {
  grid-column: span 3 / span 3;
  padding-top: 40px;
  padding-bottom: 30px;
}
.ftr_abt_prdct_prnt {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: 1600px) {
  .girid_col {
    grid-column: span 6 / span 6;
    padding-top: 35px;
  }
}
@media screen and (max-width: 1023px) {
  .girid_col {
    padding-bottom: 35px;
    padding-top: 20px;
  }
  .footer_p {
    line-height: 27px;
  }
  .footer_p_frst {
    line-height: 22px;
  }
  .foter_h1 {
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 700px) {
  .grid_cod_raw {
    grid-column-gap: 0px;
  }
  .girid_col {
    grid-column: span 12 / span 12;
  }
}
@media screen and (max-width: 500px) {
  .girid_col {
    padding-top: 10px;
  }
}
.respncv_pdng {
  padding: 0 10px;
}
.asactnitm {
  display: flex;
  align-items: center;
  background: #101120;
  border: 1px solid #202231;
  border-radius: 10px;
  min-height: 70px;
  padding: 5px 10px;
  cursor: pointer;
}
.asactnitm .font-bold {
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
}
.asactnitm .font-bold .rounded-full {
  margin: 0 5px;
}
.asactnitm .mtmsk_ic {
  margin-left: 10px;
}
@media screen and (max-width: 575px) {
  .asactnitm {
    padding: 5px 5px;
  }
  .asactnitm .font-bold {
    font-size: 14px;
  }
  .asactnitm img {
    width: 20px;
  }
}
.addtkntwlt_btn {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  transition: all 0.2s ease-out;
}
.addtkntwlt_btn:hover {
  opacity: 0.5;
}
.addtkntwlt_btn img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-left: -5px;
}

.swap_prnt .flex.gap-1.justify-between.items-baseline {
  align-items: center;
}
.ftr_sprt_prnt {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1600px) {
  .ftr_sprt_prnt {
    justify-content: flex-start;
  }
}
@media screen and (max-width: 400px) {
  .swap_prnt .flex.gap-1.justify-between.items-baseline {
    flex-direction: column;
  }
}
.mrgn_btm_14 {
  margin-bottom: 14px;
}
@media screen and (max-width: 1023px) {
  .mrgn_btm_14 {
    margin-bottom: 7px;
  }
}
@media screen and (max-width: 350px) {
  .foter_h1 {
    font-size: 15px;
    line-height: 23px;
  }
  .footer_p {
    font-size: 13px;
    line-height: 22px;
  }
  .footer_p_frst {
    line-height: 19px;
  }
}
.spop_burn_img_as {
  padding: 4px !important;
}
.discnct_wllt_pup_btn {
  background: #161522 !important;
  border: 0.5px solid rgba(9, 147, 236, 0.5) !important;
  font-size: 12px !important;
  line-height: 16px !important;
  font-family: "DM Sans" !important;
}
.font_14 {
  font-size: 14px !important;
}
/**/
.landing_page .mn_header {
  display: none;
}
.landing_page header.w-full.flex {
  opacity: 0 !important;
}
.landing_page .footerMain {
  padding-left: 0;
  margin: 0;
  max-width: 1600px;
}
.as_header {
  /* position: fixed;
  left: 0;
  top: 0; */
  width: 100%;
  background: #0b1221;
  padding: 18px 10px;
  z-index: 9999;
}
.a_logo {
  position: relative;
  height: 33px;
  width: 167px;
  cursor: pointer;
}
.header_cont {
  max-width: 1600px;
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.header_cont_max_w {
  max-width: 1160px !important;
}
.right_header {
  display: flex;
  align-items: center;
}
.right_header #connect-wallet {
  border-radius: 4px;
  background-image: linear-gradient(45deg, #00ffcf 0%, #3d5dff 38.8%, #ff73e1 66.6%, #ffd148 100%) !important;
  height: 38px;
  font-size: 14px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}
.right_header #connect-wallet:hover {
  background-image: linear-gradient(-45deg, #00ffcf 0%, #3d5dff 38.8%, #ff73e1 66.6%, #ffd148 100%) !important;
}
.rspnv_bx {
  display: flex;
  align-items: center;
}
.rwrd_lnk {
  display: flex;
  align-items: center;
  margin-right: 50px;
}
.rwrd_lnk a {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 16px;
  display: inline-flex;
  text-transform: uppercase;
  background: linear-gradient(118.31deg, #00ffcf 25.95%, #3d5dff 51.69%, #ff73e1 66.62%, #ffd148 91.25%);
  -webkit-background-clip: text;
  -webkit-color: transparent;
  background-clip: text;
  color: transparent;
  margin-left: 4px;
}
.rwrd_lnk a:hover {
  background: linear-gradient(190deg, #00ffcf 25.95%, #3d5dff 51.69%, #ff73e1 66.62%, #ffd148 91.25%);
  -webkit-background-clip: text;
  -webkit-color: transparent;
  background-clip: text;
  color: transparent;
}
.rwrd_lnk > span {
  top: -2px;
}
.as_menu_itms li {
  margin-right: 80px !important;
  margin-left: 0 !important;
}
.as_menu_itms li button,
.as_menu_itms li > a {
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  outline: none !important;
}
.as_menu_itms li button:hover,
.as_menu_itms li > a:hover,
.as_menu_itms li > a.active {
  color: #e870e4;
}
.as_menu_itms li .relative a {
  font-family: "Poppins", sans-serif;
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  padding: 10px 20px;
}
.as_menu_itms li .relative a:hover,
.as_menu_itms li .relative a.active {
  color: #e870e4;
}
.as_menu_itms li.clr_mn_swp:hover button {
  color: #00ffcf;
}
.as_menu_itms li.clr_mn_pool:hover button {
  color: rgba(61, 93, 255, 1);
}
.as_menu_itms li.clr_mn_farm:hover button {
  color: rgba(255, 209, 72, 1);
}
.as_menu_itms li.clr_mn_iccrm:hover a {
  color: #ff71e1;
}

.as_menu_itms li.clr_mn_prtlo:hover a {
  color: rgba(105, 80, 221, 1);
}
.as_menu_itms li.clr_mn_rwrd:hover a {
  color: #2bb7b6;
}
@media screen and (max-width: 1440px) {
  .as_menu_itms li {
    margin-right: 13px !important;
    margin-left: 13px !important;
  }
  .as_menu_itms li button,
  .as_menu_itms li > a {
    font-size: 14px;
  }
  .rwrd_lnk {
    margin-right: 30px;
  }
  .header_cont {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 1023px) {
  .a_logo {order: 2;}
  .mblnvbr {
    background: #ffffff1f;
  }
  .right_header {
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
  }
  .right_header ul {
    flex-direction: column;
    align-items: flex-start;
  }
  .as_menu_itms li {
    margin-bottom: 25px !important;
  }
  .rspnv_bx {
    flex-direction: column;
    align-items: flex-start;
  }
  .rwrd_lnk {
    margin-bottom: 20px !important;
    margin-top: 5px;
  }
}
@media screen and (max-width: 530px) {
  .rspnv_bx {
    margin-top: 10px;
  }
}
.banner_sc {
  padding: 180px 15px 0 15px;
}
.firs_sec_snp_jg {
  position: relative;
  height: 222px;
  width: 533px;
  margin-bottom: 60px;
}
.firs_sec_snp_jg span {
  z-index: 2;
}
.firs_sec_snp_jg span img {
    /* Standard logo (Left logo seperate) */
    /* padding-left: 140px !important; */
    padding-left: 115px !important;
    margin-bottom: -10px !important;

    /* Holiday logo (with left logo included) */
    /* padding-left: 20px !important;
    margin-bottom: -10px !important; */

}
.hed_text_box {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.hed_text_box h6 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 72px;
  color: #ffffff;
  margin-bottom: 12px;
}
.hed_text_box p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #d3dbef;
  max-width: 407px;
  margin-bottom: 90px;
}
.bttm_boxes {
  width: 100%;
  margin-bottom: 240px;
  padding: 0 15px;
}
.cstmcntnr {
  max-width: 1600px;
  margin: 0 auto;
}
.sec_two_grd_box {
  background: #131928;
  min-height: 216px;
  /* max-width: 356px; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  border-radius: 12px;
}
.img_width_box {
  position: relative;
  width: 56px;
  height: 56px;
}
.sec_two_grd_box h5 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  margin-top: 25px;
}
.clrswp:hover .sec_two_grd_box h5 {
  color: #00ffcf;
}
.clrpool:hover .sec_two_grd_box h5 {
  color: rgba(61, 93, 255, 1);
}
.clrfarm:hover .sec_two_grd_box h5 {
  color: rgba(255, 209, 72, 1);
}
.clriccmvn:hover .sec_two_grd_box h5 {
  color: #ff71e1;
}
.clrprtfo:hover .sec_two_grd_box h5 {
  color: rgba(105, 80, 221, 1);
}
.clrrwrd:hover .sec_two_grd_box h5 {
  color: #2bb7b6;
}
.mngrdnt_bx {
  background: linear-gradient(134.33deg, transparent.14%, transparent 39.5%, transparent 66.98%, transparent 100%);
  padding: 1px;
  border-radius: 12px;
  transition: all 0.2s ease-out;
}
.mngrdnt_bx:hover {
  background: linear-gradient(134.33deg, #00ffcf 1.14%, #3d5dff 39.5%, #ff73e1 66.98%, #ffd148 100%);
}
@media screen and (max-width: 1280px) {
  .bttm_boxes {
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 991px) {
  .banner_sc {
    padding-top: 40px;
  }
  .firs_sec_snp_jg {
    height: 124px;
    margin-bottom: 10px;
    width: 100%;
  }
  .hed_text_box h6 {
    font-size: 25px;
    line-height: 60px;
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 575px) {
  .banner_sc {
    padding-top: 24px;
  }
  .hed_text_box h6 {
    font-size: 20px;
    line-height: 40px;
    margin-bottom: 8px;
  }
  .sec_two_grd_box {
    min-height: 165px;
  }
  .sec_two_grd_box h5 {
    margin-top: 10px;
    font-size: 18px;
  }
  .hed_text_box p {
    font-size: 18px;
    margin-bottom: 40px;
    line-height: 28px;
  }
}
.any_hed_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.any_hed_box h4 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  color: #ffffff;
  margin-bottom: 55px;
}
.landing {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  /* font-size: 18px;
  line-height: 27px; */
  color: #ffffff;
}
.grd_anlayes {
  width: 100%;
  padding: 0 15px;
  margin-bottom: 230px;
  overflow: hidden;
}
.anilys_grd_box {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 220px;
  border: 1px solid rgb(99 106 122 / 20%);
  border-radius: 12px;
  flex-direction: column;
}
.anilys_grd_box h6 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  text-align: center;
}
.anilys_grd_box p {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 54px;
  background: linear-gradient(134.33deg, #00ffcf 1.14%, #3d5dff 39.5%, #ff73e1 66.98%, #ffd148 100%);
  -webkit-background-clip: text;
  -webkit-color: transparent;
  background-clip: text;
  color: transparent;
}
.anilys_grd_box_v2 {
  padding: 19px 29px 30px 29px;
  justify-content: space-between;
  display: inherit;
  margin: 0 auto;
}
.grd_in_grd_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.grd_in_grd_box p {
  font-size: 36px !important;
}
.price_by_grd_box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}
.price_by_grd_box p {
  font-size: 36px !important;
}
.swpccl_logo {
  position: relative;
  height: 60px;
  width: 60px;
  margin-right: 10px;
}
@media screen and (max-width: 1280px) {
  .grd_anlayes {
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 1024px) {
  .col-span-4 {
    grid-column: span 8 / span 4;
  }
  .d-none-md {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .anilys_grd_box {
    min-height: 120px;
  }
  .anilys_grd_box p {
    font-size: 24px !important;
    line-height: 40px;
  }
  .swpccl_logo {
    height: 45px;
    width: 45px;
  }
  .anilys_grd_box_v2 {
    padding: 19px 10px 30px 10px;
  }
}
@media screen and (max-width: 575px) {
  .anilys_grd_box p {
    font-size: 20px !important;
    line-height: 36px;
  }
  .anilys_grd_box h6 {
    font-size: 14px;
  }
  .swpccl_logo {
    height: 35px;
    width: 35px;
  }
}

/* nilesh css */

.top_gainers .table_sc h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  color: #ffffff;
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.top_gainers {
  width: 100%;
  margin-bottom: 190px;
  padding: 0 15px;
}
.table_sc {
  width: 100%;
}
.ovrfow-y {
  overflow-y: auto;
}
.table_sc table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
  min-width: 600px;
}

.table_sc table thead tr th {
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}
.table_sc table thead tr th span {
  font-size: 13px;
  line-height: 20px;
  margin-left: 10px;
}
.table_sc table thead th {
  padding: 10px 22px;
}
.table_sc table thead th:first-child {
  padding-left: 0;
}
.table_sc table tbody tr {
  background: #131928;
}
.table_sc table tbody tr td {
  padding: 22px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}
.table_sc table tbody tr td .td_img_bx {
  position: relative;
  height: 32px;
  width: 32px;
  min-width: 30px;
  margin-right: 27px;
}
.table_sc table tbody tr td .img_tble_txt {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 1280px) {
  .top_gainers {
    margin-bottom: 90px;
  }
}
@media screen and (max-width: 767px) {
  .top_gainers .table_sc h4,
  .any_hed_box h4 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 40px;
  }
  .table_sc table thead tr th,
  .table_sc table tbody tr td {
    font-size: 14px;
    line-height: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .table_sc table tbody tr td .img_tble_txt {
    font-size: 16px;
    line-height: 20px;
  }
  .table_sc table tbody tr td .td_img_bx {
    min-width: 30px;
    margin-right: 5px;
  }
}
.banner_bg {
  left: 0;
  top: 46px;
  width: 100%;
  height: 790px;
  position: absolute;
  pointer-events: none;
}
@media screen and (max-width: 1199px) {
  .banner_bg {
    top: 0px;
  }
}
@media screen and (max-width: 991px) {
  .banner_bg {
    height: 690px;
  }
}
@media screen and (max-width: 767px) {
  .banner_bg {
    height: 590px;
  }
}
@media screen and (max-width: 767px) {
  .banner_bg {
    height: 490px;
  }
}
.launchpad_bg {
  top:0px;
  width: 100%;
  height: 100%;
  max-width: 100%;
  position: absolute;
  pointer-events: none;
  z-index:1;
}
.banner_bg_coins {
  width: 100%;
  height: 100%;
  max-width: 100%;
  position: absolute;
  pointer-events: none;
  z-index: 0;
}
@media screen and (max-width: 1199px) {
  .banner_bg_coins {
    top: 0px;
  }
}
@media screen and (max-width: 1024px) {
  .banner_bg_coins {
   left:0px;
  }
  .launchpad_bg {
    left:0px;
  }
}

.bgWrap {
  position: fixed;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  top: 0px;
  z-index: 0;
  pointer-events: none;
}

.launchpadBannerLogo {
  width: 380px;
  height: 120px;
}

@media screen and (max-width: 1080px) {
  .launchpadBannerLogo {
    width: 342px;
    height: 108px;
  }
}

@media screen and (max-width: 400px) {
  .launchpadBannerLogo {
    width: 285px;
    height: 90px;
  }
}

@media screen and (max-width: 767px) {
  .banner_bg_coins, .launchpad_bg, .bgWrap {
    display: none;
  }
}

.pool_cntnr {
  max-width: 1100px;
  margin: 0px auto 0 auto;
}
.pool_cntnr .table {
  padding: 1px;
  background: linear-gradient(135deg, #5af9c4 0%, #3d5dff 49.5%, #ff73e1 100%);
  border-radius: 12px;
  margin-top: 10px;
  margin-right: 15px;
  margin-bottom: 10px;
  margin-left: 15px;
}
.rwrds_cntnr {
  max-width: 1256px;
  margin: 0px auto 0 auto;
}
.rwrds_cntnr .graps_finas_bx {
  padding: 42px 38px 40px 38px;
  background: #131928;
  position: relative;
}
.rwrds_cntnr .graps_finas_bx {
  padding: 1px;
  background: linear-gradient(135deg, #5af9c4 0%, #3d5dff 49.5%, #ff73e1 100%);
  border-radius: 12px;
  margin-top: 10px;
  margin-right: 15px;
  margin-bottom: 10px;
  margin-left: 15px;
}
.rwrds_cntnr .graps_finas_bx .images_graps {
  position: relative;
  height: 32px;
  width: 227px;
  min-height: 40px;
}
.rwrds_cntnr .graps_finas_bx .images_graps_v2 {
  position: relative;
  height: 40px;
  width: 107px;
  min-height: 40px;
}
.rwrds_cntnr .graps_finas_bx .images_graps_v3 {
  width: 255px;
  min-height: 40px;
}
.grp_bg_bx {
  background: #131928;
  border-radius: 12px;
  padding: 30px 28px;
  /* min-height: 606px; */
  min-height: 100%;
}
.grp_bg_bx h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  margin-top: 10px;
  font-style: normal;
  min-height: 30px;
}
.grp_bg_bx .grpas_texts_P {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.3);
  max-width: 509px;
  padding: 12px 0 12px 0;
  min-height: 105px;
}
.grp_bg_bx .grpas_texts_P a {
  color: #0993ec;
  text-decoration: underline;
}
.grps_inpt_tx {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

}
.grps_inpt_tx_v2 {
  /* min-width: 250px; */
  width:100%
}
.grps_inpt_tx h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  display: flex;
  align-items: center;
}
.grps_inpt_tx h5.j-center {justify-content: center;width: 100%;}
.grps_inpt_tx .images_graps_tru {
  position: relative;
  height: 16px;
  width: 16px;
  margin-left: 7px;
}

.grap_onavax_title .images_graps_tru {
  position: relative;
  height: 16px;
  width: 16px;
  margin-left: 7px;
}
.grap_onavax_title h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.inpts_bxs {
  background: #1d2231;
  padding: 20px 17px 10px 17px;
  border-radius: 5px;
  margin-top: 6px;
  width: 100%;
}
.wallet_error {
  font-family: "DM Sans";
  color: #cb4545;
}
.inpts_bxs input {
  background: transparent;
  outline: none;
  border: none;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  width: 100%;
}
.grps_inner_text .inpts_bxs .balns_p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.inpts_bxs .difuls_text {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #ffffff;
}
.grp_bg_bx .rewods_text {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: rgba(255, 255, 255, 0.5);
  margin: 30px 0 25px 0;
}
.grp_bg_bx .rewods_text .dots_text {
  color: #ffffff;
  padding-left: 5px;
}
.grp_bg_bx .rewards_btn {
  border-radius: 4px;
  background-image: linear-gradient(45deg, #00ffcf 0%, #3d5dff 38.8%, #ff73e1 66.6%, #ffd148 100%) !important;
  height: 64px;
  width: 100%;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}
.grp_bg_bx .rewards_btn:hover {
  background-image: linear-gradient(-45deg, #00ffcf 0%, #3d5dff 38.8%, #ff73e1 66.6%, #ffd148 100%) !important;
}
.swap_input_bx {
  display: flex;
  align-items: center;
}
.grps_inpt_tx .swap_input_bx .images_graps_tru {
  height: 36px;
  width: 36px;
  margin-left: 0;
  margin-right: 8px;
  min-width: 36px;
}

@media screen and (max-width: 1400px) {
  .grp_bg_bx .grpas_texts_P {
    font-size: 15px;
    padding: 25px 0 25px 0;
  }
  .grps_inpt_tx h5 {
    font-size: 16px;
  }
  .inpts_bxs .difuls_text {
    font-size: 24px;
  }
  .inpts_bxs input {
    font-size: 24px;
  }
}
@media screen and (max-width: 1280px) {
  .grp_bg_bx {
    padding: 30px 20px;
    /* min-height: 580px; */
  }
  .grps_inpt_tx h5 {
    font-size: 14px;
  }
  .inpts_bxs {
    padding: 15px 5px 10px 10px;
  }
  .inpts_bxs .difuls_text {
    font-size: 20px;
  }
}
@media screen and (max-width: 1023px) {
  .grps_inpt_tx_v2 {
    min-width: 170px;
    width:100%
  }
  .grps_inpt_tx_v2 .inpts_bxs .difuls_text {justify-content: center;}
  .grps_inpt_tx_v2 .inpts_bxs {text-align: center;}
}
@media screen and (max-width: 575px) {
  .grp_bg_bx {
    padding: 20px 10px;
  }
  .grp_bg_bx h4 {
    font-size: 15px;
    line-height: 20px;
  }
  .grp_bg_bx .grpas_texts_P {
    font-size: 14px;
    padding: 15px 0 5px 0;
    min-height: 132px;
    line-height: 24px;
  }
  .inpts_bxs {
    padding: 10px 0px 10px 10px;
  }
  .grp_bg_bx .rewards_btn {
    height: 50px;
    font-size: 16px;
  }
  .grp_bg_bx .rewods_text {
    margin: 15px 0 15px 0;
    font-size: 15px;
  }
  .grps_inpt_tx h5 {min-height: 46px;align-items: flex-start;}
  .grps_inpt_tx h5.j-center {min-height: inherit;}
  .grps_inpt_tx .images_graps_tru {    margin-top: 2px;}
}
@media screen and (max-width: 414px) {
  .grps_inpt_tx h5 {
    font-size: 11px;
  }
  .grps_inpt_tx .images_graps_tru {
    height: 12px;
    width: 12px;
    margin-left: 4px;
  }
  .grps_inner_text .inpts_bxs .balns_p {font-size: 12px;}
  .grps_inpt_tx h5 {min-height: 35px;}
}
.banner_bg_v2 {
  top: -20px;
  height: 874px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-left: 246px;
}
@media screen and (max-width: 1023px) {
  .banner_bg_v2 {
    top: 0;
    padding-left: 0px;
  }
}
@media screen and (max-width: 575px) {
  .banner_bg_v2 {
    top: 50px;
  }
  .rwrds_cntnr {
    margin: 55px auto 0 auto;
  }
}

.main_sd_mn li {
  list-style: none;
  margin-bottom: 3px;
}
.main_sd_mn li > a,
.drpdwn_mn_btn > a {
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #636a7a;
  width: 100%;
  padding: 15px 0px;
  outline: none !important;
}
.main_sd_mn li > a > .mn_ic,
.drpdwn_mn_btn > a.mn_ic {
  width: 18px;
}
.main_sd_mn li > a.pro_ic:hover,
.main_sd_mn li > a.pro_ic.active {
  background: linear-gradient(134.33deg,#00ffcf 1.14%,#3d5dff 39.5%,#ff73e1 66.98%,#ffd148);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.main_sd_mn li > a.swap_ic:hover,
.main_sd_mn li > a.swap_ic.active {
  color: #00ffcf;
}
.main_sd_mn li > a.crosschain_ic:hover,
.main_sd_mn li > a.crosschain_ic.active {
  color: #ec7b7b;
}
.main_sd_mn li > a.prtfl_ic:hover,
.main_sd_mn li > a.prtfl_ic.active {
  color: #6950dd;
}
.main_sd_mn li > a.iccrm_ic:hover,
.main_sd_mn li > a.iccrm_ic.active {
  color: #ff73e1;
}
.main_sd_mn li > a.rwrdmn_ic:hover,
.main_sd_mn li > a.rwrdmn_ic.active {
  color: #2bb7b6;
}
.main_sd_mn li > a.zombiesmn_ic:hover,
.main_sd_mn li > a.zombiesmn_ic.active {
  color: #10ff3f;
}
.main_sd_mn li > a.icecrmtime_ic:hover,
.main_sd_mn li > a.icecrmtime_ic.active {
  color: #FCAD8B;

}

.main_sd_mn li > a.lp_ic:hover,
.main_sd_mn li > a.lp_ic.active {
  color: #b468f2;

}


.pro_ic:hover p,
.pro_ic.active p {
  /* color: #8bfcb8; */
  background: linear-gradient(134.33deg,#00ffcf 1.14%,#3d5dff 39.5%,#ff73e1 66.98%,#ffd148);
  -webkit-background-clip: text;
  -webkit-color: transparent;
  background-clip: text;
  color: transparent;
}
.main_sd_mn li > a > .mn_ic,
.drpdwn_mn_btn > a > .mn_ic {
  display: flex;
  align-items: center;
  width: 34px;
}
.main_sd_mn li > a > .mn_ic img,
.drpdwn_mn_btn > a > .mn_ic img {
  filter: grayscale(1) brightness(100);
  opacity: 0.3;
}
.main_sd_mn li > a:hover > .mn_ic img,
.main_sd_mn li > a.active > .mn_ic img,
.drpdwn_mn_btn > a:hover > .mn_ic img,
.drpdwn_mn_btn > a:hover > .farm_ic img,
.drpdwn_mn_btn > a:hover > .pll_ic img {
  filter: grayscale(0) brightness(100%);
  opacity: 1;
}
.drpdwn_mn_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 20px;
}
.drpdwn_mn_btn .mn_hndlr {
  color: #636a7a;
  width: 18px;
  height: 18px;
}
.drpdwn_mn_btn > a.swap_ic:hover,
.drpdwn_mn_btn[aria-expanded="true"] > a.swap_ic {
  color: #00ffcf;
}
.drpdwn_mn_btn > a.pro_ic:hover,
.drpdwn_mn_btn[aria-expanded="true"] > a.pro_ic {
  background: linear-gradient(134.33deg,#00ffcf 1.14%,#3d5dff 39.5%,#ff73e1 66.98%,#ffd148);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.drpdwn_mn_btn > a.crosschain_ic:hover,
.drpdwn_mn_btn[aria-expanded="true"] > a.crosschain_ic {
  color: #ec7b7b;
}
.drpdwn_mn_btn > a.pll_ic:hover,
.drpdwn_mn_btn[aria-expanded="true"] > a.pll_ic {
  color: #3d5dff;
}
.pll_ic.active {
  color: #3d5dff;
}
.drpdwn_mn_btn > a.farm_ic:hover,
.drpdwn_mn_btn[aria-expanded="true"] > a.farm_ic {
  color: #ffd148;
}
.farm_ic.active {
  color: #ffd148;
}
.drpdwn_mn_btn > a.stake_ic:hover,
.drpdwn_mn_btn[aria-expanded="true"] > a.stake_ic {
  color: #1ee209;
}
.stake_ic.active {
  color: #1ee209;
}

.drpdwn_mn_btn > a.pll_ic.active .mn_ic img,
.drpdwn_mn_btn > a.farm_ic.active .mn_ic img,
.drpdwn_mn_btn > a.stake_ic.active .mn_ic img,
.drpdwn_mn_btn > a.stake_ic.active .mn_ic img,
.drpdwn_mn_btn > a.crosschain_ic.active .mn_ic img {
  filter: grayscale(0) brightness(100%);
  opacity: 1;
}
.drpdwn_mn_btn[aria-expanded="true"] > a.pll_ic .mn_ic img,
.drpdwn_mn_btn[aria-expanded="true"] > a.farm_ic .mn_ic img,
.drpdwn_mn_btn[aria-expanded="true"] > a.stake_ic .mn_ic img,
.drpdwn_mn_btn[aria-expanded="true"] > a.crosschain_ic .mn_ic img,
.drpdwn_mn_btn[aria-expanded="true"] > a.swap_ic .mn_ic img {
  filter: grayscale(0) brightness(100%);
  opacity: 1;
}
.drpdwn_mn_btn[aria-expanded="true"] .mn_hndlr {
  transform: rotate(180deg);
}
.inner_mn_list {
  padding: 0 0px 0 30px;
  position: relative;
  left: -4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.inner_mn_list a {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  padding: 9px 0;
  width: 100%;
}
.inner_mn_list a:hover:before,
.inner_mn_list a.active:before {
  content: "";
  position: absolute;
  right: -3px;
  top: calc(50% - 7.5px);
  height: 15px;
  width: 2px;
  background: #fff;
}
.big_logo {
  position: absolute;
  /* left: -36px; */
  left: -65px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.big_logo video, .big_logo svg {
  width: 222px !important;
}
.small_logo {
  position: absolute;
  right: -9px;
  bottom: 25px;
  width: 75px;
}
@media screen and (max-width: 991px) {
  .big_logo video, .big_logo svg {
    width: 120px !important;
  }
  .big_logo {
    left: 0px;
  }
  .small_logo {
    right: 46px;
    bottom: 5px;
    width: 50px;
  }
  .firs_sec_snp_jg span img {
    /* Standard logo (Left logo seperate) */
    padding-left: 98px !important;
    padding-right: 50px !important;

    /* Holiday logo (with left logo included) */
    /* padding-left: 20px !important;
    padding-right: 20px !important; */
  }
}
@media screen and (max-width: 440px) {
  .firs_sec_snp_jg {
    height: 90px;
    margin-bottom: 50px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  .big_logo {
    left: 0px;
    top: 7px;
  }
  .big_logo video, .big_logo svg {
    width: 80px !important;
  }
  .small_logo {
    right: 35px;
    bottom: 0px;
    width: 39px;
  }
  .firs_sec_snp_jg span img {
    /* Standard logo (Left logo seperate) */
    padding-left: 68px !important;
    padding-right: 38px !important;

    /* Holiday logo (with left logo included) */
    /* padding-left: 20px !important;
    padding-right: 20px !important; */
  }
}
.banner_bg_v3 {
  height: auto !important;
  top: 0 !important;
}
.inner_mn_list.inner_mn_list_pro a.active, .inner_mn_list.inner_mn_list_pro a:hover {
  background: linear-gradient(134.33deg,#00ffcf 1.14%,#3d5dff 39.5%,#ff73e1 66.98%,#ffd148);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.inner_mn_list.inner_mn_list_swap a.active, .inner_mn_list.inner_mn_list_swap a:hover {
  color: #00ffcf;
}
.inner_mn_list.inner_mn_list_pools a.active, .inner_mn_list.inner_mn_list_pools a:hover {
  color: #3d5dff;
}
.inner_mn_list.inner_mn_list_bridge a.active, .inner_mn_list.inner_mn_list_bridge a:hover {
  color: #ec7b7b;
}
.inner_mn_list.inner_mn_list_farms a.active, .inner_mn_list.inner_mn_list_farms a:hover {
  color: #ffd148;
}
/* .swap_page .min-100 {min-height: calc(100vh - 200px) !important;margin-bottom: -100px;}
.swap_page main.flex{
  background: url('../../public/images/swap_bg.svg') no-repeat 60% 12%;
  background-size: 1255px;
}
.pool_page main.flex{
  background: url('../../public/images/pool_bg.svg') no-repeat right top;
}
.farm_page main.flex{
  background: url('../../public/images/farm_bg.svg') no-repeat right top;
}
.portfolio_page .flex.flex-col.flex-grow.w-full{
  background: url('../../public/images/portfolio_bg.svg') no-repeat right top;
}
@media screen and (max-width: 1440px) {
  .pool_page main.flex {
    background: url("../../public/images/pool_bg.svg") no-repeat right top;
    background-size: contain;
  }
  .farm_page main.flex {
    background: url("../../public/images/farm_bg.svg") no-repeat center top;
    background-size: contain;
  }
  .portfolio_page .flex.flex-col.flex-grow.w-full {
    background: url("../../public/images/portfolio_bg.svg") no-repeat center top;
    background-size: contain;
  }
} */


.top_bg_prnt {
  position: absolute;
  left: 0;
  top: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 160px;
  pointer-events: none;
}
.top_bg_prnt_prtfl {
  top: 280px;
}
@media screen and (max-width: 1023px) {
  .top_bg_prnt {padding-left: 0px;}
}
@media screen and (max-width: 600px) {
  .top_bg_prnt {display: none !important;}
}
/*  */

.as_flxrow {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.col-md-4, .col-md-3, .col-md-7, .col-md-5, .col-md-33, .col-md-12 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-md-4 {
  flex: 0 0 30%;
  max-width: 30%;
}
.col-md-3 {
  flex: 0 0 23.33%;
  max-width: 23.33%;
}
.col-md-7 {
  flex: 0 0 70%;
  max-width: 70%;
}
.col-md-5 {
  flex: 0 0 35%;
  max-width: 35%;
}
.col-md-33 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  position: relative;
  width: 100%;
  min-height: 1px;
  /* padding-right: 40px; */
  /* padding-bottom: 40px; */
}
.col-md-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.col-md-12 h3 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #515A6F;
  margin-bottom: 25px;
}
.nls_net_wrth {
  background: #131928 !important;
  border-radius: 12px;
  min-height: 160px;
  /* display: flex; */
  /* align-items: flex-start; */
  /* justify-content: flex-start; */
  /* max-width: 100%; */
  width: 100%;
  padding: 20px 30px;
}
.as_wrtbx {
  display: flex;
  align-items: center;
}
.brdr_main {
  position: relative;
  padding: 1px;
  background: linear-gradient(164deg, #00FFCF 0%, #3D5DFF 38.8%, #FF73E1 66.6%, #FFD148 100%);
  border-radius: 12px;
  margin: 0 4px;
  width: 100%;
}
.nls_frst_bx {
  background: #131928;
  border-radius: 14px;
  align-items: center;
  justify-content: center;
  min-height: 167px;
  width: 100%;
  display: flex;
  padding: 12px;
}
.nls_frst_bx canvas {
  width: 91px !important;
  height: 91px !important;
}
.nls_brd_non {
  border: none;
}
.oxa_txt_nls {
  margin-left: 14px;
  margin-right: 20px;
}
.images_graps_nls01 {
  position: relative;
  height: 49px;
  width: 38px;
}
.images_graps_nls02 {
    position: relative;
    height: 39px;
    width: 49px;
}
.images_graps_nls03 {
    position: relative;
    height: 44px;
    width: 47px;
}
.main_p_12 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #636A7A;
}
.nls_mrgn {
  margin-top: 51px;
  margin-bottom: 17px;
}
.assets_main_bx {
  background: #131928;
  border-radius: 16px;
  padding: 22px 26px 13px 20px;
}
.eth_txt_bx {
  display: flex;
  align-items: center;
}
.eth_ethrm_txt h5{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #FFFFFF;
}
.eth_ethrm_txt h6{
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #636A7A;
}
.eth_img01 {
  position: relative;
  height: 32px;
  width: 32px;
  margin-right: 10px;
}
.eth_14_txt {
  margin-top: 30px;
}
.eth_14_txt h3{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #FFFFFF;
  margin-bottom: 6px;
}
.eth_14_txt h3 span{
  font-size: 16px;
  line-height: 24px;
  margin-left: 4px;
}
.dolr_txt_etg {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dolr_txt_etg .spn_red {
  color: #FF5A86;
}
.dolr_txt_etg h4{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #FFFFFF;
}
.dolr_txt_etg span{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #00FFCF;
}
.eth_arrow_bx .right_arrow {
  position: relative;
  height: 16px;
  width: 16px;
  margin: 0 12px;
}
.eth_arrow_bx {
  display: flex;
  align-items: center;
}
.main_table_nls table {
  width: 100%;
  margin-top: 24px;
}
.main_table_nls h3 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}
.swap_pm_txt span {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #636A7A;
}
.panding_btn {
  border: 1px solid #424753 ;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #636A7A;
  max-width:78px;
  height: 26px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nls_tbl_tr:hover .panding_btn {
  color: #fff;
}
.nls_tbl_tr {
  border-bottom: 1px solid rgba(66, 71, 83, 0.5);
}
.nls_tbl_tr td {
  padding-bottom: 18px;
  padding-top: 21px;
}
.nls_tbl_tr td:first-child {
  padding-top: 0;
}
@media screen and (max-width: 1600px) {
  .as_flxrow {
    margin: 0 15px;
  }
}
@media screen and (max-width: 1280px) {
  .col-md-33 {
    padding-right: 15px;
    padding-bottom: 15px;
  }
  .nls_mrgn {
    padding-left: 0px;
  }
  .nls_frst_bx {
    flex-direction: column;
  }
  .nls_net_wrth {
    flex-direction: column;
  }
  .oxa_txt_nls {
    margin-left: 5px;
    margin-top: 5px;
  }
  .oxa_txt_nls .leading-5, .oxa_txt_nls .leading-7, .oxa_txt_nls .leading-6 {
    text-align: center;
  }
}
@media screen and (max-width: 991px) {
  .col-md-3 {
    padding-right: 5px;
    padding-left: 5px;
  }
}
@media screen and (max-width: 767px) {
  .col-md-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .nls_net_wrth {
    max-width: 100%;
  }
  .nls_frst_bx {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 500px) {
  .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-md-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .brdr_main {
    margin-bottom: 10px;
  }
  .nls_frst_bx {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 1280px) {
  .col-md-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-md-5 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .col-md-33 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .nls_mrgn {
    margin-top: 15px;
  }
  .as_flxrow {
    margin: 0 0px;
  }
  .eth_14_txt h3 {
    font-size: 20px;
  }
  .main_table_nls h3 {
    font-size: 12px;
  }
  .swap_pm_txt span {
    font-size: 12px;
  }
  .eth_arrow_bx .right_arrow  {
    margin: 0 5px;
  }
}
@media screen and (min-width: 1024px) {
  .rspnsv_pl_as {margin-left: 160px !important;}
}
.as_wrtbx .nls_h5_txt {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #FFFFFF;
}
.nls_sml_txt {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 11px !important;
  line-height: 16px !important;
  color: #636A7A !important;
  text-transform: uppercase;
}
.pt-0 {
  padding-top: 10px !important;
}
.as_t_heading {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #FFFFFF;
  margin-bottom: 6px;
}
.padi_lf_ab .overflow-x-auto {
  background-color: transparent;
  border-radius: 0;
}
.padi_lf_ab table thead th {
  font-family: 'Poppins', sans-serif;
  background: #131928;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #515A6F;
  padding-top: 3px;
  padding-bottom: 3px;
  border: none;
}
.padi_lf_ab table tbody td {
  border-top: none;
  border-bottom: 1px solid rgb(66 71 83 / 50%);
  background: #0b1221cf;
}
.padi_lf_ab table tbody td .text-high-emphesis{
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  color: #FFFFFF;
  white-space: nowrap;
}
.padi_lf_ab table tbody td .text-low-emphesis{
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #636A7A;
}
@media screen and (max-width: 1500px) {
  .nls_net_wrth {
    padding: 20px 0px;
  }
}
@media screen and (max-width: 1280px) {
  .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .nls_frst_bx .oxa_txt_nls {margin-right: 0px;}
  .nls_frst_bx {
    margin-bottom: 15px;
  }
  .padi_lf_ab table tbody td .text-high-emphesis{
    font-size: 16px;
    line-height: 24px;
  }
  .padi_lf_ab table tbody td .text-low-emphesis{
    font-size: 11px;
  line-height: 17px;
  }
}
@media screen and (max-width: 1400px) {
  .now_woeth_as .font-bold {font-size: 20px;}
}
/* #headlessui-disclosure-panel-2 .inner_mn_list a:hover {
  color: #3d5dff !important;
} */
/* #headlessui-disclosure-panel-4 .inner_mn_list a:hover {
  color: #ffd148 !important;
} */
.lnddrpdwn {
  background: #0b1221;
  border: 2px solid #363e4f;
  border-radius: 0;
}
.as_menu_itms li .relative.lndngswpmn a:hover, .as_menu_itms li .relative.lndngswpmn a.active {
  color: #00ffcf;
}
.as_menu_itms li .relative.lnd_plsmn a:hover, .as_menu_itms li .relative.lnd_plsmn a.active {
  color: rgba(61, 93, 255, 1);
}
.as_menu_itms li .relative.lnd_farms a:hover, .as_menu_itms li .relative.lnd_farms a.active {
  color: rgba(255, 209, 72, 1);
}
.portflo_analitics_main {
  width: 100%;
}
.anlitics_top_bx {
  display: flex;
  align-items: center;
  width: 100%;
}
.anlitics_top_bx .left_box {
  background: #131928;
  border-radius: 14px;
  max-width: 660px;
  width: 55%;
  padding: 30px 25px 20px 25px;
  min-height: 167px;
}
.dflex_spac_btvn_prnt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.crncy_text_prnt {
  display: flex;
  align-items: center;
}
.crncy_text_prnt h4 {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 42.3766px;
  color: #FFFFFF;
  margin-left: 24px;
}
.copy_text_prnt {
  background: linear-gradient(rgb(19, 25, 40) 0px, rgb(19, 25, 40) 0px) padding-box padding-box, linear-gradient(135deg, #00FFCF, #3D5DFF, #FF73E1, #FFD148 ) border-box border-box;
  border: 1px solid transparent;
  border-radius: 30px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 21px;
  color: #636A7A;
  font-family: 'Poppins', sans-serif;
  height: 42px;
}
.copy_text_prnt .copy_btn {
  margin-left: 10px;
}
.anlitics_top_bx .btm_p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #636A7A;
  font-family: 'Poppins', sans-serif;
  margin-top: 25px;
}
.anlitics_top_bx .right_box {
  background: linear-gradient(rgb(19, 25, 40) 0px, rgb(19, 25, 40) 0px) padding-box padding-box, linear-gradient(135deg, #00FFCF, #3D5DFF, #FF73E1, #FFD148 ) border-box border-box;
  border-radius: 14px;
  width: 45%;
  padding: 40px;
  border: 1px solid transparent;
  min-height: 167px;
  display: flex;
  align-items: center;
  max-width: 577px;
  margin-left: 21px;
}
.right_box .price_cap_bxs {
  width: 50%;
}
.right_box .price_cap_bxs p {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #636A7A;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 14px;
}
.right_box .price_cap_bxs h5 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}
@media screen and (max-width: 1279px) {
  .anlitics_top_bx .left_box {
    padding: 20px 15px 15px 15px;
    min-height: 125px;
  }
  .curncy_logo_as {
    height: 50px;
    width: 50px;
  }
  .crncy_text_prnt h4 {
    font-size: 32.3766px;
    margin-left: 15px;
  }
  .copy_text_prnt {
    font-size: 18px;
    line-height: 13px;
    height: 37px;
  }
  .anlitics_top_bx .btm_p {
    margin-top: 16px;
    font-size: 15px;
  }
  .anlitics_top_bx .right_box {
    min-height: 125px;
    padding: 25px;
  }
  .right_box .price_cap_bxs p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .right_box .price_cap_bxs h5 {
    font-size: 22px;
    line-height: 27px;
  }
}
@media screen and (max-width: 830px) {
  .anlitics_top_bx {
    flex-direction: column;
    align-items: flex-start;
  }
  .anlitics_top_bx .right_box {
    width: 100%;
    margin-left: 0;
    margin-top: 13px;
  }
  .anlitics_top_bx .left_box {
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .curncy_logo_as {
    height: 36px;
    width: 36px;
  }
  .crncy_text_prnt h4 {
    font-size: 24.3766px;
    margin-left: 6px;
  }
  .anlitics_top_bx .left_box {
    padding: 20px 10px 15px 10px;
    min-height: 0;
  }
  .copy_text_prnt {
    font-size: 16px;
    line-height: 16px;
    height: 32px;
  }
  .copy_text_prnt .copy_btn {
    margin-left: 5px;
    height: 24px;
    width: 22px;
  }
  .anlitics_top_bx .btm_p {
    margin-top: 14px;
    font-size: 14px;
    line-height: 19px;
  }
  .anlitics_top_bx .right_box {
    margin-top: 10px;
    padding: 15px 10px;
    min-height: 0;
  }
  .right_box .price_cap_bxs p {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 7px;
  }
  .right_box .price_cap_bxs h5 {
    font-size: 20px;
    line-height: 25px;
  }
}
.analitics_overview {
  padding: 15px 25px 35px 25px;
  border-radius: 14px;
  background:
  rgba(19, 25, 40, 0.6);
  margin-top: 35px;
}
.analitics_def_h {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #636A7A;
}
.graf_boxes_prnt {
  display: flex;
  width: 100%;
  margin-top: 35px;
}
.graf_boxes_prnt .graf_box {
  background: #202737;
  border-radius: 10px;
  padding: 15px 5px 15px 20px;
  width: 100%;
}
.graf_boxes_prnt .graf_box_right {
  margin-left: 10px;
}
.dflx_justbtvn {
  display: flex;
  justify-content: space-between;
}
.dflx_justbtvn h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  font-family: 'Poppins', sans-serif;
}
.dflx_justbtvn h3 {
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  font-family: 'Poppins', sans-serif;
}
.dflx_justbtvn h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  line-height: 25px;
  color: #FFFFFF;
  font-weight: 400;
}
.dflx_justbtvn p {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #C9CCE3;
}
.dflx_justbtvn p span{
  color: #4CEEC0;
  margin-right: 5px;
}
.graf_img_prnt {margin-top: 10px;}
.price_chart_img_prnt {
  padding: 25px 15px 0 15px;
}
@media screen and (max-width: 1099px) {
  .dflx_justbtvn h6 {
    font-size: 15px;
    line-height: 24px;
  }
  .dflx_justbtvn h3 {
    font-size: 20px;
    line-height: 31px;
  }
  .dflx_justbtvn h2 {
    font-size: 22px;
    line-height: 24px;
  }
  .dflx_justbtvn p {
    font-size: 15px;
    line-height: 24px;
  }
}
@media screen and (max-width: 767px) {
  .graf_boxes_prnt {
    display: flex;
    flex-direction: column;
  }
  .graf_boxes_prnt .graf_box_right {
    margin-left: 0;
    margin-top: 10px;
  }
}
@media screen and (max-width: 575px) {
  .analitics_overview {
    padding: 15px 10px 25px 10px;
  }
  .graf_boxes_prnt {
    margin-top: 23px;
  }
  .graf_boxes_prnt .graf_box {
    padding: 15px 5px 10px 10px;
  }
  .dflx_justbtvn h6 {
    font-size: 14px;
    line-height: 22px;
  }
  .dflx_justbtvn h3 {
    font-size: 18px;
    line-height: 28px;
  }
  .dflx_justbtvn h2 {
    font-size: 20px;
    line-height: 22px;
  }
  .dflx_justbtvn p {
    font-size: 14px;
    line-height: 22px;
  }
  .price_chart_img_prnt {
    padding: 15px 0 0 0;
  }
}
.analitics_price_chart {
  margin-top: 24px;
  margin-bottom: 24px;
}
.lvf_info_box {
  background: rgba(19, 25, 40, 0.6);
  border-radius: 14px;
  padding: 12px 0 9px 0;
}
.grid_cod_raw_as {
  display: grid;
  grid-row-gap: var(--chakra-space-4);
  grid-column-gap: 10px;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  justify-content: center;
  padding: 28px 0;
}
.girid_col_4 {
  grid-column: span 4 / span 4;
}
.border_none {
  border: none !important;
}
.lvf_box_inn {
  padding: 10px 10px 0 30px;
  border-left: 1px solid #2F384E;
  min-height: 80px;
}
.lvf_box_inn p {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #FFFFFF;
}
.lvf_box_inn h3 {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 45px;
  color: #FFFFFF;
}
.lvf_box_inn h3 span {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.colr_green {color: #4CEEC0;}
.colr_red {color: #E84142;}
.lvf_info_dvdr {
  height: 1px;
  max-width: 100%;
  background: #2F384E;
  margin: 0 12px;
}
.lvf_info_box .analitics_def_h {
  margin-top: 28px;
  margin-bottom: -13px;
  padding-left: 30px;
}
.lvf_box_inn h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;
}
.lvf_box_inn h2 {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #FFFFFF;
  margin-top: 9px;
}
@media screen and (max-width: 1380px) {
  .ellipse_text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 200px;
  }
}
@media screen and (max-width: 991px) {
  .grid_cod_raw_as {
    padding: 20px 0;
  }
  .lvf_box_inn p {
    font-size: 13px;
    line-height: 19px;
  }
  .lvf_box_inn h3 {
    font-size: 24px;
    line-height: 36px;
    margin-top: 4px;
  }
  .lvf_box_inn h6 {
    font-size: 11px;
    line-height: 13px;
  }
  .lvf_box_inn h2 {
    font-size: 13px;
    line-height: 19px;
    margin-top: 7px;
  }
  .lvf_info_box .analitics_def_h {
    margin-top: 17px;
    margin-bottom: -10px;
    padding-left: 20px;
  }
  .lvf_box_inn {
    padding: 10px 10px 0 20px;
    min-height: 64px;
  }
  .ellipse_text {
    width: 180px;
  }
}
@media screen and (max-width: 700px) {
  .girid_col_4 {
    grid-column: span 12 / span 12;
  }
  .lvf_box_inn {
    border: none;
    margin-top: 10px;
    min-height: 0;
  }
}
.trnstctn_hstr_tbl_box {
  border-radius: 14px;
  background: rgba(19, 25, 40, 0.6);
  margin-top: 24px;
  padding-bottom: 23px;
}
.dflx_just_btvn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 17px 20px 30px;
}
.serch_inpt_box {
  display: flex;
  align-items: center;
  height: 54px;
  max-width: 350px;
  width: 100%;
  border: 1px solid #2F384E;
  padding: 0 12px;
  border-radius: 8px;
}
.serch_inpt_box input {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
  background: transparent;
  margin-left: 6px;
}
.serch_inpt_box input::placeholder {
  color: #636A7A;
}
.trnsctn_tbl_prnt {
  margin-bottom: 70px;
  overflow-x: auto;
}
.trnsctn_tbl_prnt table {
  width: 100%;
  min-width: 800px;
}
.trnsctn_tbl_prnt table thead {
  padding: 0 30px;
  background: #202737;
  text-align: left;
}
.trnsctn_tbl_prnt table thead tr th {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #636A7A;
  padding: 6px 0 4px 0;
}
.trnsctn_tbl_prnt table thead tr th:first-child {
  padding-left: 30px;
}
.trnsctn_tbl_prnt table thead tr th svg {
  height: 8px;
  display: inline;
}
.trnsctn_tbl_prnt table tbody tr td {
  border-bottom: 1px solid #2F384E;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  font-family: 'Poppins', sans-serif;
  padding: 20px 0;
}
.trnsctn_tbl_prnt table tbody tr td:first-child {
  padding-left: 30px;
}
.pagination_btns_prnt {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination_btns_prnt .arw_btn svg {
  width: 15px;
  color: #424753;
}
.pagination_btns_prnt .arw_btn svg:hover {
  color: #9297a3;
}
.pagination_btns_prnt .arw_btn {
  margin: 0 12px;
}
.pagination_btns_prnt .num_btn_as {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #636A7A;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2px;
}
.pagination_btns_prnt .num_btn_as.active, .pagination_btns_prnt .num_btn_as:hover {
  border: 0.5px solid #636A7A;
}
.portflo_analitics_main {
  background: url(../../public/images/prtflo_analitcs_bg.jpg) no-repeat 0 0;
  background-size: cover;
  background-position: 1px -107px;
  margin-top: 0;
  padding-top: 68px;
}
@media screen and (max-width: 1099px) {
  .trnsctn_tbl_prnt table tbody tr td {
    font-size: 14px;
    line-height: 20px;
    padding: 15px 0;
  }
  .portflo_analitics_main {
    background-position: 1px -80px;
    padding-top: 50px;
  }
}
@media screen and (max-width: 767px) {
  .dflx_just_btvn {
    padding: 12px 12px 16px 20px;
  }
  .serch_inpt_box {
    height: 46px;
    max-width: 300px;
  }
  .serch_inpt_box input {
    font-size: 16px;
  }
  .trnsctn_tbl_prnt {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 575px) {
  .dflx_just_btvn {
    flex-direction: column;
  }
  .dflx_just_btvn .analitics_def_h {
    margin-bottom: 5px;
  }
  .trnsctn_tbl_prnt {
    margin-bottom: 40px;
  }
  .portflo_analitics_main {
    background-position: 0 -70px;
    padding-top: 40px;
  }
}
@media screen and (min-width: 500px) {
  .swpmdl_wnd_auto {
    width: auto !important;
    max-width: inherit !important;
    min-width: 28rem;
  }
}

.max-380 {max-width: 380px;}

.max-1000 {
  max-width: 1030px;
  padding: 0 15px;
  margin: 0 auto;
}
.flex_prnt .lft_link_teb{
  max-width: 610px !important;
  width: 100%;
  margin-right: 40px;
  position: relative;
}
.flex_prnt .apr_h {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
  margin-bottom: 18px;
}

.grp_bg_bx .apr_h {
  margin-bottom: 20px;
}
.ic_hed_top_prnt{
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}
.ic_hed_top_prnt img{
  padding-right: 8px !important;
}
.ic_hed_top_prnt h2{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  color: #FFFFFF;
}
.rewards #add-liquidity-page .ic_hed_top_prnt {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

.rewards #add-liquidity-page .rewards-heading {
  margin-left: 45px;
  margin-right: 45px;
}
/* .rewards #add-liquidity-page .grid {
  margin-left: 25px;
  margin-right: 30px;
} */

.all-pools-heading {
  /* margin-left: 45px;
  margin-right: 45px; */
  margin-bottom: 35px;
}

.all-pools-heading h3{
  font-family: 'DM Sans';
  font-size: 17px;
}

.all-pools-heading h4{
  font-family: 'DM Sans';
  font-size: 15px;
  margin-top: 30px;
}

.all-pools-title {
  margin-bottom: 0px;
}

.all-pools-menu {
  /* margin-left: 45px;
  margin-right: 45px; */
  margin-bottom: 35px !important;
}

.flex_prnt .lft_link_teb ul.menu_tp_list{
  display: flex;
  width: 100%;
  max-width: 540px;
  height: 54px;
  border-radius: 35px;
  background: #1D2231;
  align-items: center;
  justify-content: space-between;
}
.flex_prnt .lft_link_teb ul.menu_tp_list li{
  width: 100%;
}
.flex_prnt .lft_link_teb ul.menu_tp_list li a{
  color: rgba(255, 255, 255, 0.4);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  font-family: 'Poppins', sans-serif;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 54px;
  border-radius: 35px !important;
  justify-content: center;
  display: flex;
  align-items: center;
}
.flex_prnt .lft_link_teb ul.menu_tp_list li.active a{
  background: #292D3C;
  color: #FFFFFF;
}
.flex_prnt .lft_link_teb ul.menu_tp_list{
  margin-bottom: 58px;
}
.balance_box_prn_flx{
  display: flex;
  justify-content: space-between;
  border-radius: 11px;
}
.balance_box h3 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
  margin-bottom: 14px;
}
.balance_box .in_bgcol_blc_box{
  width: 285px;
  background: #1D2231;
  border-radius: 11px;
  padding: 17px 7px 17px 17px;
  display: flex;
  align-items: center;
}

.balance_box_rewards .in_bgcol_blc_box{
  width: 250px;
  background: #1D2231;
  border-radius: 11px;
  padding: 17px 7px 17px 17px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.apr_h3 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
}

.in_bgcol_blc_box .port_bx {
  margin-left: 13px;
}
.in_bgcol_blc_box .port_bx p{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #FFFFFF;
}
.in_bgcol_blc_box .port_bx span{
  color: rgba(255, 255, 255, 0.4);
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  font-family: 'Poppins', sans-serif;
}
.in_bgcol_blc_boxV2{
  margin: 0 !important;
}
.in_bgcol_blc_boxV2 .port_bx p{
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}
.in_bgcol_blc_boxV2 .port_bx{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.in_bgcol_blc_boxV2 .port_bx a{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #FFFFFF;
}
.in_bgcol_blc_box a{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  /* font-weight: 700; */
  font-size: 14px;
  line-height: 21px;
  /* color: #FFFFFF; */
  margin-left:15%;
}
.tkn_img {
  height: 45px;
  width: 45px;
  position: relative;
  min-width: 45px;
}
.teb_bx_prnt{
  width: 610px;
  background: #131928;
  padding: 44px 40px;
  margin-top: 32px;
  border-radius: 12px;
}
.creamWrap{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.teb_hed_det{
  max-width: 530px;
  width: 100%;
  height: auto;
  margin-bottom: 0px;
  background: transparent;
}
.stake_pops_btv{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
}
.stake_pops_btv h3{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
}
.stake_pops_btv h4{
   font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #FFFFFF;
  border: 1px solid #424753;
  padding: 11px;
  border-radius: 35px;
}
.input_prnt_box_min{
  width: 100%;
  background: #1D2231;
  border-radius: 11px;
  padding: 20px 14px 16px 15px;
  margin-bottom: 30px;
}
.form_ble_spbt{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 9px;
}
.form_ble_spbt h5{
  color: rgba(255, 255, 255, 0.4);
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
}
.form_ble_spbt h5 span{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #FFFFFF;
}
.form_ble_spbt{
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.4);
  font-family: 'Poppins', sans-serif;
}
.input_bx_spbt{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex_po_bc{
  display: flex;
  align-items: center;
}
.flex_po_bc h3{
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #FFFFFF;
  font-family: 'Poppins', sans-serif;
  margin-left: 10px;
}
.inp_bx_bg{
  background: #131928;
  padding-left: 14px;
  padding-right: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  height: 46px;
}
.inp_bx_bg input{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
  background: transparent;
}
.inp_bx_bg button{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #FFFFFF;
  width: 50px;
  height: 36px;
  background: #1D2231;
  border-radius: 4px;
}
.teb_bx_prnt .stak_btn_ret{
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  font-family: 'Poppins', sans-serif;
  color: #FFFFFF;
  /* background: linear-gradient(135deg, #FF73E1 0%, #FFD148 100%); */
  background: linear-gradient(135deg, #00FFCF 0%, #3D5DFF 100%) !important;
  height: 64px;
  width: 100%;
  border-radius: 8px;
  transition: all 0.4s ease-out;
}
.teb_bx_prnt .stak_btn_ret:hover{
  /* background: linear-gradient(135deg, #FFD148 0%, #FF73E1 100%) !important; */
  background: linear-gradient(135deg, #52e2c8 0%, #364cba 100%) !important;
}
.teb_bx_prnt .stak_btn_ret:disabled{
  /* background: linear-gradient(135deg, #FFD148 0%, #FF73E1 100%) !important; */
  background-color: rgb(46 51 72);
  opacity: 0.5;
  cursor: no-drop;
}
.max-1000 .flex_prnt{
  display: flex;
}
.shareText h4{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  /* font-weight: 500;
  font-size: 16px; */
  line-height: 24px;
  color: #DDDCE1;
}
.shareText h5{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #DDDCE1;
}
.cream_rit_conten_bc{
  width: 100%;
}
.Keep_your_spbtn_bx{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.Keep_your_spbtn_bx_ph {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}
.Keep_your_spbtn_bx_ph h4{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}
.Keep_your_spbtn_bx_ph P{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.4);
  font-family: 'Poppins', sans-serif;
  margin-top: 3px;
}
.lor_def_vfe_btn{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  width: 117px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
  cursor: pointer;
}
.lor_def_vfe_btn:hover{
  background: #292d3c;

}
.staking_rit_bx_ert h3{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
  margin-top: 60px;
  margin-bottom: 14px;
}
.staking_rit_viwe{
  background: #131928;
  padding: 18px 16px 17px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 11px;
  min-height: 80px;
}
.staking_rit_bx_ertv2{
  margin-top: 60px;
}
.staking_rit_bx_ertv2 .apr_h{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
  margin-bottom: 14px;
}
.staking_rit_bx_ertv2 .staking_rit_viwe .viwe_h{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #FFFFFF;
}
.staking_rit_bx_ertv2 .staking_rit_viwe .vie_p{
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.4);
  font-family: 'Poppins', sans-serif;
}
.staking_rit_bx_ertv2 .staking_rit_viwe .viwe_p_rf{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  width: 172px;
}
.staking_rit_bx_ert_v3 {
  margin-top: 33px;
}

.my-pools-content {
  padding: 20px 30px 0px 30px;
}

.my-pools-buttons {
  margin-bottom:20px;
  padding: 0px 20px 0px 20px;
}

@media all and (min-width: 1200px) {
  .my-pools-content {
    margin-left: 10% !important;
    margin-right: 10% !important;
  }
}

/* Rewards page */

@media screen and (max-width: 1500px) {
  .balance_box_prn_flx .balance_box_rewards{width: 50%;}
  .balance_box_rewards .in_bgcol_blc_box{width: 100%;}
  .balance_box_prn_flx .balance_box_rewards{margin-left: 10px;}
  .balance_box_prn_flx .balance_box_rewards:first-child{margin-left: 0px !important;}
}

@media screen and (max-width: 1290px) {
  .balance_box_prn_flx .balance_box_rewards{width: 100%;}
  .balance_box_rewards .in_bgcol_blc_box{width: 100%;}
  .balance_box_prn_flx .balance_box_rewards{margin-left: 0px;}
  .balance_box_prn_flx .balance_box_rewards:first-child{margin-left: 0px !important;}
  .grp_bg_bx .rewards_btn{margin-top: 30px;}
  .balance_box_rewards {margin-top: 20px;}
}

@media screen and (max-width: 1024px) {
  .grp_bg_bx .grpas_texts_P {max-width: fit-content;}
  .balance_box_rewards a {margin-left: 5%;}
}

@media screen and (max-width: 500px) {
  #add-liquidity-page .grid {margin-left:-5px;}
  #add-liquidity-page .grid {margin-right:-5px;}
  #add-liquidity-page .rewards-heading {margin-left: 12px;}
  #add-liquidity-page .rewards-heading {margin-right: 12px;}
  .grp_bg_bx .grpas_texts_P {max-width: fit-content;}
  .balance_box_rewards a {margin-left: 5%;}
  #add-liquidity-page .ic_hed_top_prnt h2 {font-size: 25px; margin-left: 5px !; margin-bottom: 0px !important;}
  #add-liquidity-page .ic_hed_top_prnt {margin-left: 15px !important; }
  #add-liquidity-page .ic_hed_top_prnt img { margin-left: 12px; }
}

@media screen and (max-width: 1290px) {
  .flex_prnt .lft_link_teb ul.menu_tp_list{width: 100%; max-width: 100%;}
  .flex_prnt .lft_link_teb ul.menu_tp_list li{width: 100%; max-width: 100%;}

  .all-pools-heading {
    margin-left: 0px;
    width: 87%;
  }
  .flex_prnt .pools_menu ul.menu_tp_list{
    max-width: 1250px !important;
    margin-left: 0px;
    /* width: 50%; */
  }

  .max-1000 .flex_prnt{
    flex-direction: column;
  }
  .flex_prnt .lft_link_teb{
    max-width: 100%;
    margin-right: 0 !important;
  }
  .teb_bx_prnt{
    width: 100%;
  }
  .balance_box_prn_flx .balance_box{width: 50%;}
  .balance_box .in_bgcol_blc_box{width: 100%;}
  .balance_box_prn_flx .balance_box{margin-left: 10px;}
  .balance_box_prn_flx .balance_box:first-child{margin-left: 0px !important;}
  .teb_hed_det{width: 100%; max-width: 100%;}
  .cream_rit_conten_bc{margin-top: 30px;}

  /* .balance_box_prn_flx .balance_box_rewards{width: 50%;}
  .balance_box_rewards .in_bgcol_blc_box{width: 100%;}
  .balance_box_prn_flx .balance_box_rewards{margin-left: 10px;}
  .balance_box_prn_flx .balance_box_rewards:first-child{margin-left: 0px !important;} */
}
@media screen and (max-width: 575px) {

  .pools-table .row-hidden{
    display:none;
    width:0;
    height:0;
    opacity:0;
    visibility: collapse;
  }

  .pool_browse {
    font-size: 13px;
  }



  .flex_prnt .pools_menu ul.menu_tp_list{width: 100%; max-width: 100%;}
  .flex_prnt .pools_menu ul.menu_tp_list li{width: 100%; max-width: 100%;}

  .flex_prnt .lft_link_teb ul.menu_tp_list li a{
    font-size: 12px;
  }
  .flex_prnt .lft_link_teb ul.menu_tp_list{
    height: 40px;
    margin-bottom: 28px;
  }
  .flex_prnt .pools_menu ul.menu_tp_list{
    height: 40px;
    margin-bottom: 20px;
    margin-left: 0px;
    /* width: 50%; */
  }
  .all-pools-heading {
    margin-bottom: 0px;
    /* width: 50%; */
  }
  .all-pools-heading h3{
    font-size: 13px;
    margin-bottom: 20px;
  }
  .flex_prnt .lft_link_teb ul.menu_tp_list li a{height: 40px;}
  .ic_hed_top_prnt h2{
    font-size: 30px;
  }
  .ic_hed_top_prnt{
    margin-bottom: 12px;
  }
  .balance_box_prn_flx{
    flex-direction: column;
  }
  .balance_box_prn_flx .balance_box {
    width: 100%;
  }
  .balance_box_prn_flx .balance_box:first-child {
    margin-bottom: 25px;
  }
  .teb_bx_prnt{
    padding: 15px;
    margin-top: 30px;
  }
  .stake_pops_btv h3 {font-size: 16px;}
  .stake_pops_btv { flex-direction: column;}
  .input_bx_spbt{ flex-direction: column; margin-top: 20px;}
  .form_ble_spbt{font-size: 12px;}
  .form_ble_spbt h5{font-size: 13px;}
  .form_ble_spbt h5 span{
    font-size: 14px;
  }
  .flex_po_bc{margin-bottom: 4px;}
  .inp_bx_bg input{width: 140px;}
  .teb_bx_prnt .stak_btn_ret{ height: 47px;}
  .staking_rit_bx_ert_v3 {
    margin-top: 25px;
  }
  .staking_rit_bx_ertv2 .apr_h {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .staking_rit_bx_ertv2 .staking_rit_viwe .viwe_p_rf {font-size: 14px; line-height: 20px;}
  .staking_rit_bx_ertv2 .staking_rit_viwe .viwe_h {font-size: 20px; line-height: 30px;}
  .staking_rit_bx_ertv2 {margin-top: 40px;}
}
@media screen and (max-width: 400px) {
  .Keep_your_spbtn_bx{
    flex-direction: column;
    padding: 0;
  }
  .lor_def_vfe_btn{margin-top: 10px;}
  .staking_rit_viwe{flex-direction: column;}
  .staking_rit_bx_ertv2 .staking_rit_viwe .viwe_p_rf{width: 100%;}
  .staking_rit_bx_ertv2 .staking_rit_viwe .viwe_h{text-align: center;}
  .staking_rit_bx_ertv2 .staking_rit_viwe .vie_p{text-align: center;}
  .staking_rit_bx_ertv2 {
    margin-top: 30px;
  }
}
.hidden.dsplblck {
  display: block !important;
}
.btn_chang_pop{
  background: #1D2231;
  width: 100%;
  height: 54px;
  padding: 0;
}
.btn_chang_pop button.active{
  background: #292D3C !important;
  background-image: none !important;
  color: #fff;
  height: auto;
  border-radius: 5px !important;
}
.btn_chang_pop button{
  color: rgba(255, 255, 255, 0.4);
}
.teb_hed_det{
    border-radius: 8px;
}
.teb_inrr_data{
  margin-top: 18px;
}
.pogrs_prnt .rc-slider-track{
  background: url(./img/progress_bg.svg) no-repeat center;
  background-size: cover;
  z-index: 9;
}
.pogrs_prnt .rc-slider-handle{
  width: 25px !important;
  height: 25px !important;
  margin-top: -10px !important;
  border: none !important;
  z-index: 9;
}
.pogrs_prnt .rc-slider-step{
  background: #424753 ;
}
.tri_pogrs_tp{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 31px;
  margin-bottom: 10px;
}
.tri_pogrs_tp p{
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  width: 60px;
  color: rgba(255, 255, 255, 0.4);
  font-family: 'Poppins', sans-serif;
}
.tri_pogrs_tp p:first-child{
  text-align: left;
}
.tri_pogrs_tp p:nth-child(2) {
  text-align: center;
}
.tri_pogrs_tp p:last-child {
  text-align: right;
}
.tri_pogrs_tp_v2{
  margin-top: 10px !important;
  margin-bottom: 30px;
}
.min_prt_bg{
  background: #1D2231;
  width: 100%;
  max-width: 245px;
  height: 110px;
  padding: 11px 14px 11px 14px;
  border-radius: 6px;
}
.min_prt_bg .img_tex_bx{
  display: flex;
  align-items: center;
  margin-bottom: 11px;
}
.avtr_space {
  position: relative;
  height: 30px;
  width: 30px;
}
.avtr_space.mr-2 {width: 32px;height: 32px;}
.min_prt_bg .img_tex_bx h3{
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
  margin-left: 13px;
}
.inp_bx_err {
  border: 1px solid red;
}
.inp_bx_bg input{
  max-width: 145px;
}
.stak_btn_retV2def{
  background: linear-gradient(135deg, #00FFCF 0%, #3D5DFF 100%) !important;
  margin-top: 30px;
}
.min_prt_bgV2{
  height: 125px;
}
.min_prt_bgV2_prnt_flex{
  display: flex;
  justify-content: space-between;
}
.Keep_your_spbtn_bx_ph_sec p{
  max-width: 176px;
  margin-top: 3px;
}
.staking_rit_bx_ert_sec {
  margin-top: 33px !important;
}
.staking_rit_viwe_sec h3{
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: rgba(255, 255, 255, 0.4);
  font-family: 'Poppins', sans-serif;
}
.staking_rit_viwe_sec p{
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #fff;
}
.avx_prgrs_txt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.staking_rit_viwe_sec {
  padding: 30px 15px 22px 17px;
}
.prgrs_br_sc {
  background: #131928;
  min-height: 80px;
  border-radius: 11px;
  padding: 12px 16px 9px 16px;
}
.prgrs_br_sc p{
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #FFFFFF;
  font-family: 'Poppins', sans-serif;
}
.avlbty_prgrsbr {
  margin: 4px 0 7px 0;
}
.avlbty_prgrsbr .rc-slider-track{
  background: linear-gradient(90deg, #5AF9C4 0%, #3D5DFF 38%, #FF73E1 73.16%, #FFD148 98.2%);
  background-size: 318px;
  height: 6px;
}
.avlbty_prgrsbr .rc-slider-disabled {
  background-color: transparent !important;
}
.avlbty_prgrsbr .rc-slider-handle {
  display: none;
}
.avlbty_prgrsbr .rc-slider-rail {
  height: 6px;
  background: #1D2231;
}

@media screen and (max-width: 575px){
  .brdg_modal_minVersion {
    margin-right: 182px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 575px){
  .brdg_small {
    min-width: 320px;
  }
}
.switch-arrow {
  max-width: 10px;
  max-height: 10px;
  min-width: 10px;
  min-height: 10px;
  margin-bottom: 8px !important;
  margin-top: 1px !important;
  overflow: visible !important;
  padding-bottom: 8px !important;
}

.switch-arrowDiv {
  max-width: 26px;
  max-height: 26px;
  min-width: 26px;
  min-height: 26px;
  overflow: visible !important;
}

@media screen and (max-width: 575px){
  .crncy_select_prnt .crncy_select_brdg__menu {
    max-width: 150px !important;
    background: #131928;
    border-radius: 6px;
    overflow: hidden;
    font-size: 10px !important;
    overflow-x: hidden !important;
    padding: 53px 8px 10px 9px;
    top: 48px;
  }

  .crncy_select_prnt .crncy_select_brdg__menu::after {
    content: "Choose network";
    position: absolute;
    top: 15px;
    left: 11px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 15px !important;
    line-height: 20px;
    color: #FFFFFF;
    opacity: 0.7;
  }

  .crncy_select_prnt .crncy_select_brdg__menu::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0px;
    border: 1px solid transparent;
    background: linear-gradient(rgb(19, 25, 40) 0px, rgb(19, 25, 40) 0px) padding-box padding-box, linear-gradient(135deg, #00FFCF, #3D5DFF,#FF73E1,#FFD148) border-box border-box;
    /* background: linear-gradient(270.07deg, #00FFCF, #3D5DFF, #00FFCF,#FFD148 ); */
    mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
    border-radius: 6px;
    pointer-events: none;
  }

  .crncy_select_prnt .crncy_select_brdg__menu {
    overflow-x: hidden !important;
    min-width: 150px !important;
  }
}


.position_prnt {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #131928;
  border-radius: 11px;
}
.position_prnt .avx_ten {
  padding: 13px 11px 8px 11px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 166px;
  background: #1D2231;
  border-radius: 11px;
}
.spop_thund {
  margin-left: 15px;
}
.spop_thund h6 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  font-family: 'Poppins', sans-serif;
  color: #FFFFFF;
}
.spop_thund p {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  font-family: 'Poppins', sans-serif;
  color: rgba(255, 255, 255, 0.4);
}
.rwrd_prnt .avx_ten {
  background: transparent;
}
.rwrd_prnt .avx_ten button{
  border: 1px solid rgba(255, 255, 255, 1);
  width: 147px;
  height: 40px;
  border-radius: 35px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  padding: 20px;
}
.rwrd_prnt .avx_ten button:hover {
  background: #292d3c;
}

@media screen and (max-width: 1280px) {
  .min_prt_bgV3 {
    max-width: 100%;
    margin-right: 12px;
  }
  .min_prt_bgV2 {
    max-width: 100%;
  }
  .input_bx_spbt {
    display: inherit;
  }
  .position_prnt .avx_ten {
    max-width: 100%;
  }
  .position_prnt .avx_ten:first-child {
    margin-right: 12px;
  }
  .avx_ten_bttn {
    justify-content: center;
  }
}
@media screen and (max-width: 575px) {
  .max-1000{
    padding: 0;
  }
  .min_prt_bgV3 {
    margin-right: 0;
    margin-bottom: 12px;
  }
  .teb_hed_det .min_prt_bgV2_prnt_flex {
    flex-direction: column !important;
  }
  .position_prnt .avx_ten {
    padding: 6px;
  }
  .rwrd_prnt .avx_ten button {
    width: 130px;
    height: 34px;
  }
  .position_prnt_rspns {
    flex-direction: column !important;
  }
  .position_prnt_rspns .avx_ten:first-child{
    margin-bottom: 12px;
  }
}
.slct_amunt h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
  margin-bottom: 15px;
  margin-top: 30px;
}
.radio_list {
  margin: 0 0 25px 0;
  padding: 0;
  display: flex !important;
  align-items: center !important;
  width: auto !important;
  justify-content: inherit !important;
}
.radio_list li {
  list-style: none;
  position: relative;
  margin-right: 8px;
  width: auto !important;

}

.radio_list li input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
}
.radio_list li label {
  font-family: "Poppins", sans-serif;
  background: #1D2231;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #FFFFFF;
  height: 46px;
  width: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.radio_list li input:checked + label {
  background: linear-gradient(135deg, #00FFCF 0%, #3D5DFF 100%);
}

.in_bgcol_blc_box_vault {
  justify-content: space-between;
}
.in_bgcol_blc_box_vault h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #FFFFFF;
}
.in_bgcol_blc_box_vault .thether_imgg {
  display: flex;
  align-items: center;
}
.in_bgcol_blc_box_vault .thether_imgg h4 {
  margin-left: 13px;
}
.dpsit_usc_txt {
  width: 100%;
  max-width: 245px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 27px;
  margin-bottom: 11px;
}
.dpsit_usc_txt h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
}
.dpsit_usc_txt h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.4);
}
.teb_bx_prnt .dpsit_vault {
  background: linear-gradient(135deg, #FF73E1 0%, #3D5DFF 100%) !important;
}
.teb_bx_prnt .dpsit_vault:hover {
  background: linear-gradient(135deg, #3D5DFF 0%, #FF73E1 100%) !important;
}
.inp_bx_bg_sec {
  width: 100%;
  background: #292D3C;
}
.spop_thund_vault p::before{
  content: "*";
}
.mddl_button {
  display: flex;
  align-items: center;
  padding-top: 60px;
}
.frst_depst, .frst_depst_as {
  min-width: 245px;
}

@media all and (min-width: 1024px) and (max-width: 1130px){
  .pro-leaderboard {
    padding-right:300px !important;
  }
}
@media all and (min-width: 1130px) and (max-width: 1200px){
  .pro-leaderboard {
    padding-right:280px !important;
  }
}
@media all and (min-width: 1200px) and (max-width: 1300px){
  .pro-leaderboard {
    padding-right:220px !important;
  }
}
@media all and (min-width: 1300px) and (max-width: 1500px){
  .pro-leaderboard {
    padding-right:150px !important;
  }
}

@media all and (min-width: 1024px) and (max-width: 1500px){
  .pools-table {
    padding-right:280px;
  }
  .all-pools-heading {
    padding-right:150px;
  }
  .poolpage_prnt {
    margin-right:400px;
  }

  .tow_btn2 {
    width: 80px;
  }
}
@media screen and (max-width: 1290px) {
  .balance_box_prn_flx .balance_box {
    width: 100%;
  }
}
@media screen and (max-width: 1290px) {
  .avlbty_prgrsbr .rc-slider-track{
    background-size: calc(100vw - 338px);
  }
}
@media screen and (max-width: 1024px) {
  .avlbty_prgrsbr .rc-slider-track{
    background-size: calc(100vw - 107px);
  }
}
@media screen and (max-width: 1280px) {
  .min_prt_bgV2_prnt_flex_sec .frst_depst{
    width: 100%;
    margin-right: 12px;
  }
  .min_prt_bgV2_prnt_flex_sec .frst_depst:last-child{
    margin-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .dpsit_usc_txt {
    width: 100%;
    max-width: 100%;
  }
  .min_prt_bgV2_prnt_flex_sec {
    flex-direction: column;
  }
  .mddl_button {
    padding-top: 30px;
    justify-content: center;
  }
  .mddl_button button {
    transform: rotate(90deg);
  }
}
@media screen and (max-width: 575px ) {
  .balance_box_prn_flx .balance_box {
    margin-left: 0;
  }
}
.drpdwn_mn_btn > a.stake_ic:hover,
.drpdwn_mn_btn[aria-expanded="true"] > a.stake_ic {
  color: rgb(255, 255, 255);
}
.drpdwn_mn_btn[aria-expanded="true"] > a.stake_ic .mn_ic img {
  filter: grayscale(0) brightness(100%);
  opacity: 1;
}
.ice_van, .ice_vault, .ice_box {
  color: #fff;
}
.ice_van.active, .ice_van:hover {
  color: #FCAD8B;
}
.ice_box.active, .ice_box:hover {
  color: #3EBCCB;
}
.ice_vault.active, .ice_vault:hover {
  color: #C468EF;
}
.ice_vault.active img, .ice_box.active img, .ice_van.active, img {
  filter: grayscale(0) brightness(100%);
  opacity: 1;
}
.drpdwn_mn_btn > a.ice_vault .mn_ic img {
  filter: grayscale(0) brightness(100%);
  opacity: 1;
}
.drpdwn_mn_btn > a.ice_box .mn_ic img {
  filter: grayscale(0) brightness(100%);
  opacity: 1;
}
.drpdwn_mn_btn > a.ice_van .mn_ic img {
  filter: grayscale(0) brightness(100%);
  opacity: 1;
}
.stake_bg {
  background: url('/images/stake_bg.png') no-repeat 72% top;
}

.img_p_bx_flex{
  display: flex;
  align-items: center;
}
.img_p_bx_flex p{
  margin-left: 8px;
}
.position_prnt .avx_ten_ab{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.avx_ten_ab .spop_thund{
  margin-left: 0;
  margin-top: 5px;
}
.avx_ten_ab .spop_thund h6{
  font-size: 20px;
}
.position_prnt .avx_ten{
  padding: 10px 11px 5px 11px;
}
.crncy_select_prnt .crncy_select {
  background: transparent;
  min-width: 200px;
}
.crncy_select_prnt .crncy_select_inn__control {
  background: transparent;
  border: none;
}
.crncy_select_prnt .crnc_options {
  display: flex;
  align-items: center;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #FFFFFF;
}
.crncy_select_prnt .crnc_options>span {
  margin-right: 8px !important;
  min-width: 28px;
}
.crncy_select_prnt .crncy_select_inn__indicator-separator { display: none; }
.crncy_select_prnt .crncy_select_inn__menu {
  min-width: 337px;
  background: #131928;
  border-radius: 6px;
  overflow: hidden;
  padding: 53px 20px 10px 9px;
  top: 48px;
}
.crncy_select_prnt .crncy_select_inn__menu::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0px;
  border: 1px solid transparent;
  background: linear-gradient(rgb(19, 25, 40) 0px, rgb(19, 25, 40) 0px) padding-box padding-box, linear-gradient(135deg, #00FFCF, #3D5DFF,#FF73E1,#FFD148) border-box border-box;
  /* background: linear-gradient(270.07deg, #00FFCF, #3D5DFF, #00FFCF,#FFD148 ); */
  -webkit-mask: linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
    mask:linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask-composite: destination-out;
  border-radius: 6px;
  pointer-events: none;
}
.crncy_select_prnt .crncy_select_inn__menu::after {
  content: "Choose network";
  position: absolute;
  top: 15px;
  left: 11px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #FFFFFF;
  opacity: 0.7;
}
.crncy_select_inn__menu .crncy_select_inn__menu-list>div .crnc_options {
  background: #0D0415;
  border: 1px solid #202231;
  border-radius: 8px;
  padding: 6px 14px;
  margin-bottom: 10px;
}
.crncy_select_inn__menu .crncy_select_inn__option.crncy_select_inn__option {
  padding-left: 3px;
}
.crncy_select_inn__menu .crncy_select_inn__option.crncy_select_inn__option--is-selected .crnc_options {
  position: relative;
  border: 0.5px solid #00D67C;
  overflow: visible;
}
.crncy_select_inn__menu .crncy_select_inn__option.crncy_select_inn__option--is-selected .crnc_options::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  background: #00D67C;
  left: -3px;
  border-radius: 50%;
}
.crncy_select_inn__indicator svg {
  color: #fff !important;
}
.crncy_select_inn__control--menu-is-open .crncy_select_inn__indicator{
  rotate: 180deg;
}
.crncy_select_inn__menu .crncy_select_inn__menu-list {
  padding: 0;

}
.crncy_select_inn__menu .crncy_select_inn__menu-list>div {
  padding: 0;
  background: transparent;
}
/* .crncy_select_inn__option .crncy_select_inn__option--is-focused  {
  background: transparent;
} */

@media screen and (max-width:1023px) {
  .crncy_select_prnt .crncy_select_inn__menu {
    min-width: 180px;
  }
  .crncy_select_prnt .crnc_options {
    font-size: 13px;
  }
  .crncy_select_prnt .crnc_options>span {
    height: 25px !important;
    width: 25px !important;
    margin-right: 5px;
  }
}
.crncy_select_prnt_ash {
  margin-left: 20px;
}
.crncy_select_prnt_ash .crncy_select {
  background: #0D0415;
  border: 1px solid #202231;
  border-radius: 60px;
}
.crncy_select_prnt .crncy_select_inn__control {
  border: none !important;
  box-shadow: none !important;
}
.crncy_select_prnt_ash .crncy_select_inn__menu {
  min-width: 150px;
  background: #131928;
  border-radius: 6px;
  overflow: hidden;
  padding: 20px 20px 10px 9px;
  top: 35px;
}
.crncy_select_prnt_ash .crncy_select_inn__menu::after {
  display: none;
}
.ascrrncslct {
  background: linear-gradient(rgb(19, 25, 40) 0px, rgb(19, 25, 40) 0px) padding-box padding-box, linear-gradient(135deg, rgb(90, 249, 196) 0%, rgb(61, 93, 255) 50%, rgb(255, 115, 225) 100%) border-box border-box;
  border: 2px solid transparent;
  border-radius: 10px;
}


.wthdrow_mdl_main .harvestSuccessTitle{
  margin-top: 10px;
}
.wthdrow_mdl_main .harvestSuccessText{
  margin-bottom: 20px;
  margin-top: 10px;
}
.table-bk {
  background-color: #131928;
}

.wthdrow_mdl_main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.wthdrow_mdl_main h2 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #FFFFFF;
  margin-bottom: 25px;
}
.wthdrow_mdl_main p {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 15px;
  max-width: 348px;
  text-align: center;
}
.wthdrow_mdl_main h4 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 20px;
  max-width: 348px;
  text-align: center;
}
.wthdrow_mdl_main h5 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 28px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 20px;
  max-width: 348px;
  text-align: center;
}
.wthdrow_mdl_main p span {
  font-weight: bold;
}
.wthdrow_mdl_main h4 span {
  font-weight: bold;
}
.wthdrow_mdl_main .cncel_cnfrn_btn {
  display: flex;
  align-items: center;
}

.wthdrow_mdl_main .cncel_cnfrn_btn button{
  color: #fff;
  width: 200px;
  height: 47px;
  border-radius: 4px;
  transition: all 0.3s ease-out;
}
.wthdrow_mdl_main .cncel_cnfrn_btn .cncl_btn {
  font-family: 'Poppins', sans-serif;
  background: #292D3C;
  margin-right: 11px;
}
.wthdrow_mdl_main .cncel_cnfrn_btn button:hover {
  opacity: 0.7;
}
.wthdrow_mdl_main .cncel_cnfrn_btn .cnfrm_btn {
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(315deg, #FF73E1 0%, #3D5DFF 100%);
}
@media screen and (max-width: 575px) {
  .wthdrow_mdl_main h2 {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 22px;
  }
  .wthdrow_mdl_main p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;
  }
  .wthdrow_mdl_main .cncel_cnfrn_btn button {
    width: 100px;
    height: 37px;
  }
  .wthdrow_mdl_main .cncel_cnfrn_btn .cncl_btn {
    font-family: 'Poppins', sans-serif;
    margin-right: 6px;
  }
  .crncy_select_prnt .crncy_select {
    background: transparent;
    min-width: 110px;
  }
}


/* Ice ceremTime */

.ice_cream_claim_mx {
  display: flex;
  align-items: center;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
}

.ice_time_bx .ice_cream_txt {
  display: flex;
  align-items: flex-end;
  margin-bottom: 14px;
}
.ice_time_bx .ice_cream_txt h2 {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 53px;
  color: #FFFFFF;
  margin-left: 6px;
}
.ice_crem_prgrsbr .cmlt_prz_bx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
}
.ice_crem_prgrsbr .cmlt_prz_bx h6 {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff66;
}
.ict-loading {
  width: 1.5em;
  display: inline-block;
  margin-right: 0px;
}
.progress_barr {
  background: #1D2231;
  height: 6px;
  border-radius: 12px;
}
.progress_barr .bg_width_bx {
  background: linear-gradient(63.02deg, #5AF9C4 16.87%, #3D5DFF 48.58%, #FF73E1 77.91%, #FFD148 98.81%);
  height: 6px;
  border-radius: 12px;
}

.cmlt_prz_bx_67_100 {
  margin-top: 7px;
}
.cmlt_prz_bx_67_100 h4 {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
}

.right_clim_rwrd {
  background: #131928;
  padding: 8px 15px 8px 8px;
  display: flex;
  align-items: center;
  border-radius: 12px;
}
.claim_txt {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  margin-left: 30px;
}
.claim_txt h2 {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #FFFFFF;
  max-width: 256px;
  margin-bottom: 40px;
}
.claim_txt button {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #FFFFFF;
  background: linear-gradient(90deg, #00FFCF 0%, #3D5DFF 38.8%, #FF73E1 66.6%, #FFD148 100%);
  width: 280px;
  height: 46px;
  border-radius: 12px;
  transition: all 0.3s ease-out;
}
.claim_txt button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
/* .claim_txt button {
  background: #292D3C;
  color: #ffffff80;
} */
.claim_txt button:hover {
  opacity: 0.7;
}
@media screen and (max-width: 1280px) {
  .claim_txt {
    margin-left: 18px;
  }
  .claim_txt h2 {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .claim_txt button {
    width: 200px;
  }
}
@media screen and (max-width: 768px) {
  .ice_cream_claim_mx {
    display: flex;
    align-items: center;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(1, minmax(0px, 1fr));
  }
}
@media screen and (max-width: 575px) {
  .ice_time_bx .ice_cream_txt h2 {
    font-size: 28px;
    line-height: 44px;
  }
  .right_clim_rwrd {
    flex-direction: column;
  }
  .claim_txt h2 {
    margin-top: 12px;
  }
  .claim_txt button {
    width: 100%;
  }

}



/* btmm side */
.weekly_task_bx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 50px;
}
.weekly_task_bx h6 {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
}
.weekly_task_bx h6 span {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff80;
  margin-right: 35px;
}
@media screen and (max-width: 575px) {
  .weekly_task_bx h6 {
    font-size: 14px;
    line-height: 18px;
  }
  .weekly_task_bx h6 span {
    font-size: 14px;
    line-height: 21px;
    margin-right: 12px;
  }
}

.tooltip-icv {
  font-family: "Poppins", sans-serif;
}

.tooltip-icv-hide {
  visibility: hidden;
  display: none;
}

.chckbx_main_bxx {
  align-items: center;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(4, minmax(0px, 1fr));
}
.chedck_box_main {
  position: relative;
}
.chkbx_in_bxx {
  background: #131928;
  border-radius: 12px;
  padding: 12px;
  width: 100%;
  min-height: 260px;
}
.chedck_box_main input {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  opacity: 0;
  height: 100%;
}

.cmplt_swp_txt {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 14px;
}
.cmplt_swp_txt h2{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #FFFFFF;
  margin-top: 30px;
  text-align: center;
  max-width: 150px;
}
.cmplt_swp_txt .ice_cream_img>span {
  filter: invert(1) brightness(20);
  opacity: 0.1 !important;
}
.chedck_box_main input:checked + .chkbx_in_bxx .ice_cream_img>span {
  filter: invert(0) brightness(1) !important;
  opacity: 1 !important;
}
.true_img_bx_cheked {
  display: none;
}
.chedck_box_main input:checked + .chkbx_in_bxx .true_img_bx_cheked {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}
.chedck_box_main input:checked + .chkbx_in_bxx .true_img_bx {
  display: none !important;
}
.true_img_bx {
  display: flex !important;
  align-items: center;
  justify-content: space-between;

}




@media screen and (max-width: 1280px) {
  .chckbx_main_bxx {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(4, minmax(0px, 1fr));
  }
}
@media screen and (max-width: 991px) {
  .chckbx_main_bxx {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(2, minmax(0px, 1fr));
  }
}
@media screen and (max-width: 575px) {
  .chckbx_main_bxx {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(1, minmax(0px, 1fr));
  }
}



.close_btn {
  position: relative;
}
.close_btn button {
  position: absolute;
  right: -22px;
  top: -22px;
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
}
.trophy_bx {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.trophy_bx h2 {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #FFFFFF;
  margin-top: 60px;
  margin-bottom: 8px;
}
.trophy_bx p {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #FFFFFF;
  max-width: 250px;
}
.trophy_bx button {
  background: linear-gradient(90deg, #00FFCF 0%, #3D5DFF 38.8%, #FF73E1 66.6%, #FFD148 100%);
  margin-top: 60px;
  width: 400px;
  height: 47px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  border-radius: 12px;
  transition: all 0.3s ease-out;
}
.trophy_bx button:hover {
  opacity: 0.8;
}
@media screen and (max-width: 575px) {
  .trophy_bx h2 {
    margin-top: 20px;
  }
  .trophy_bx button {
    width: 100%;
    margin-top: 20px;
  }
}

/* Swap */

.swap-box {
  /* padding: 15px; */
  /* margin: 50px; */
  /* width: 482px; */
  width: 550px;
  /* height: 660px; */
  position: relative;
}

#swap-button {
  margin-top: 5px;
}

@media all and (min-width: 1480px) {
  #add-liquidity-page.swap-page {
    margin-left: 15% !important;
  }
}

/* onRAMP */

.onramp {
  --border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,.20);
  border-radius: var(--border-radius);
  /* display: flex;
  justify-content: center;  */
  width: 550px;
  height: 660px;
  position: relative;
}

@media all and (min-width: 0px) and (max-width: 600px) {
  .onramp {
    width: 100%;
  }

  .swap-box {
    width: 100%;
    min-width: 350px;
  }
}

@media all and (min-width: 601px) and(max-width: 1023px) {
  .onramp {
    width: 90%;
  }

  .swap-box {
    width: 90%;
  }
}

/* BRIDGE */

::-webkit-scrollbar {
	background: #131928;
	width: 4px;
}

::-webkit-scrollbar-thumb {
	background: #858990 !important;
	border-radius: 15px;
}

.brdg_container{
  margin-left: 180px;
}

@media screen and (max-width: 500px) {

  .chain-selectors{
    flex-direction: row;
    flex-wrap: wrap;
  }

  .switch-arrowDiv {
    display: none;
  }

}



.brdg_modal_btn:hover {
  background-color: #131928;
  font-weight: bolder;
}
.brdg_modal_btn {
  display:block;
  padding: 8px;
  font-weight: normal;
  text-align: left;
}

.items-left{
  align-items:flex-start;
}
.justify-left{
  justify-content: left;
}

.brdg_container_token_select{
  margin-left: 180px;
  margin-bottom: 200px;
  margin-top: 75px;
}

.crncy_select_prnt .crncy_select_brdg__control {
  border: none !important;
  box-shadow: none !important;
}
.crncy_select_prnt_ash .crncy_select_brdg__menu {
  min-width: 150px;
  background: #131928;
  border-radius: 6px;
  overflow: hidden;
  padding: 20px 20px 10px 9px;
  top: 35px;
}
.crncy_select_prnt_ash .crncy_select_brdg__menu::after {
  display: none;
}


.crncy_select_prnt .brdg_options {
  font-size: 13px;
}
.crncy_select_prnt .brdg_options>span {
  height: 30px !important;
  width: 20px !important;
  margin-right: 5px;
}

.crncy_select_prnt .crncy_select_brdg__menu {
  min-width: 280px;
}

.landing_page .crncy_select_prnt .brdg_options>span {
  margin-right: 0 !important;
}


.landing_page .crncy_select_brdg__value-container .crncy_select_brdg__single-value .crnc_options p, .landing_page .crncy_select_brdg__control .crncy_select_brdg__indicators {
  display: none;
}
.landing_page .crncy_select_brdg__value-container .crncy_select_brdg__single-value .brdg_options p, .landing_page .crncy_select_brdg__control .crncy_select_brdg__indicators {
  display: none;
}

.crncy_select_brdg__control--menu-is-open .crncy_select_brdg__indicator{
  rotate: 180deg;
}
.crncy_select_brdg__menu .crncy_select_brdg__menu-list {
  padding: 0;

}
.crncy_select_brdg__menu .crncy_select_brdg__menu-list>div {
  padding: 0;
  background: transparent;
}

.crncy_select_brdg__menu .crncy_select_brdg__option.crncy_select_brdg__option--is-selected .brdg_options::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  background: #00D67C;
  left: -3px;
  border-radius: 50%;
}
.crncy_select_brdg__indicator svg {
  color: #fff !important;
}

.crncy_select_brdg__menu .crncy_select_brdg__option.crncy_select_brdg__option--is-selected .brdg_options {
  position: relative;
  border: 0.5px solid #00D67C;
  overflow: visible;
}
.crncy_select_brdg__menu .crncy_select_brdg__option.crncy_select_brdg__option--is-selected .crnc_options::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  background: #00D67C;
  left: -3px;
  border-radius: 50%;
}
.crncy_select_inn__menu .crncy_select_inn__option.crncy_select_inn__option--is-selected .brdg_options::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  background: #00D67C;
  left: -3px;
  border-radius: 50%;
}

.crncy_select_brdg__menu .crncy_select_brdg__option.crncy_select_brdg__option {
  padding-left: 3px;
}
.crncy_select_brdg__menu .crncy_select_brdg__option.crncy_select_brdg__option--is-selected .crnc_options {
  position: relative;
  border: 0.5px solid #00D67C;
  overflow: visible;
}
.crncy_select_inn__menu .crncy_select_inn__option.crncy_select_inn__option--is-selected .brdg_options {
  position: relative;
  border: 0.5px solid #00D67C;
  overflow: visible;
}

.crncy_select_inn__menu .crncy_select_inn__menu-list>div .brdg_options {
  background: #0D0415;
  border: 1px solid #202231;
  border-radius: 8px;
  padding: 6px 14px;
  margin-bottom: 10px;
}
.crncy_select_brdg__menu .crncy_select_brdg__menu-list>div .crnc_options {
  background: #0D0415;
  border: 1px solid #202231;
  border-radius: 8px;
  padding: 6px 14px;
  margin-bottom: 10px;
}
.switch-margin_bottom{
  margin-bottom: 62px;
}
.crncy_select_brdg__menu .crncy_select_brdg__menu-list>div .brdg_options {
  background: #0D0415;
  border: 1px solid #202231;
  border-radius: 8px;
  padding: 6px 14px;
  margin-bottom: 10px;
  margin-right: 8px;
}

.crncy_select_prnt .crncy_select_brdg__menu::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0px;
  border: 1px solid transparent;
  background: linear-gradient(rgb(19, 25, 40) 0px, rgb(19, 25, 40) 0px) padding-box padding-box, linear-gradient(135deg, #00FFCF, #3D5DFF,#FF73E1,#FFD148) border-box border-box;
  /* background: linear-gradient(270.07deg, #00FFCF, #3D5DFF, #00FFCF,#FFD148 ); */
  mask: linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask-composite: destination-out;
  border-radius: 6px;
  pointer-events: none;
}
.crncy_select_prnt .crncy_select_brdg__menu::after {
  content: "Choose network";
  position: absolute;;
  top: 15px;
  left: 11px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #FFFFFF;
  opacity: 0.7;
  /* z-index: 1000; */
}

.crncy_select_prnt .crncy_select_brdg__indicator-separator { display: none; }
.crncy_select_prnt .crncy_select_brdg__menu {
  min-width: 226px;
  background: #131928;
  border-radius: 6px;
  overflow: hidden;
  padding: 53px 8px 10px 9px;
  top: 48px;
}

.crncy_select_prnt .brdg_options>span {
  margin-right: 8px !important;
  min-width: 20px;
}

.crncy_select_prnt .brdg_options {
  display: flex;
  align-items: center;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #FFFFFF;
}

.crncy_select_prnt .crncy_select_brdg__control {
  background: transparent;
  border: none;
}
.bottom-13 {
  bottom: 3.2rem/* 56px */;
}

.main_sd_mn li > a.bridge:hover,
.main_sd_mn li > a.bridge.active {
  color: #c468ee;
}

.clrbrdg:hover .sec_two_grd_box h5 {
  color: #c468ee;
}

.as_menu_itms li.clr_mn_brdg:hover a {
  color: #c468ee;
}

.MuiStepLabel-label{
  color: #ffffff;
}
.MuiStepLabel-label.Mui-completed{
  color: #25eca0 !important;
}
.MuiStepLabel-label.Mui-active{
  color: #25eca0 !important;
}
.MuiStepLabel-label.Mui-error{
  color:#d32f2f !important;
}
.borderBrdgConfirm{
  border: 2px solid #1d2231 !important;
  border-radius: 12px;
}
.borderBrdgRate{
  border: 1px solid #1d2231 !important;
  border-radius: 12px;
}
.brdg_search{
  background-color:#1D2231;
}
.brdg_margin{
  margin-top: 128px;
}
.tokenName_small{
  font-size: x-small;
  color:gray ;
}
.Snackbar_margin{
  margin-left: 90px;
  background-color: #1D2231;
  font-weight: bold;
}
.Snackbar_margin_loader{
  margin-left: 90px;
  background-color: #1D2231;
  padding: 15px;
  font-weight:400;
  border: 2px solid transparent;
}

.selectChain:focus {
  outline: none !important;
}
.swapsicle__bridge--form input:focus {

  -webkit-appearance: none;
  appearance: none;
}

.swapsicle__bridge--form .MenuItem {
  border-color:
    linear-gradient(135deg, #5AF9C4 0%, #3D5DFF 50%, #FF73E1 100%);
  border: 2px solid transparent;
  font-size: large;
}
.border_brdg{
  border: 1px solid #0b1221;
  border-radius: 12px;
}

.hrBrdg{
  border: 1px solid #1d2231;
  /* #1d2231 */
}

.border_brdg_input{
  border: 1px solid #0b1221;
  border-radius: 12px;
}
.border_brdg_input:focus{
  border: 1px solid rgba(9, 147, 236, 1) !important;
  border-radius: 12px;
}

.swapsicle__bridge--form input {
	background-color: #1D2231;
  font-size: large;
}

.swapsicle__bridge--form .Mui-focusVisible {
  background-color: #131928;
}
.balance_brdg{
  cursor: pointer;
}
.brdg_modal_container{
  background:
    linear-gradient(#131928 0 0) padding-box,linear-gradient(135deg, #5AF9C4 0%, #3D5DFF 50%, #FF73E1 100%) border-box;
  border: 2px solid transparent;
  border-radius: '10px';
}
.selectChain {
  /* -webkit-appearance: none;
  appearance: none; */
	/* background-color: #131928; */
  color: #e5e7eb;
  font-size: large;
  text-align: center;
  outline: none !important;
}


.bannerBridgeInput {
  margin-bottom: -20px;
}

.bannerBridgeInput::placeholder {
  color: #ffffff;
  opacity: 1;
  /* Firefox */
}

.bannerSwapCurrency {
  border-radius: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: -65px;
  background: #292D3C;
}

.bannerSwapCurrency:hover {
  border-radius: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: -65px;
  background: #2E3348;
}

.backdrop-blur {
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.leaderboard h3{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  z-index: 1;
}

.pro_leaderboard_table {
  padding: 2px;
  background: linear-gradient(135deg, #5af9c4 0%, #3d5dff 49.5%, #ff73e1 100%);
  border-radius: 12px;
  margin-top: 10px;
  /* margin-right: 15px; */
  margin-bottom: 10px;
  /* margin-left: 15px; */
  z-index: 1;
}
.pro_leaderboard_box {
  background: #131928;
  border-radius: 12px;
  padding: 30px 28px;
  /* min-height: 606px; */
  min-height: 100%;
  z-index: 1;
  position:relative;
}

.pro_leaderboard_subtable,
.pro_leaderboard_statstable {
  padding: 2px;
  border-radius: 12px;
  /* margin-top: 25px;  */
  margin-bottom: 10px;

  /* margin-right: 15px; */
  /* margin-left: 15px; */
  z-index: 2;
}
.pro_leaderboard_subtable:hover {
  padding: 2px;
  background-image: linear-gradient(150deg, #00ffcf 0%, #3d5dff 33%, #ff73e1 66%, #ffd148 100%);
  border-radius: 12px;
  /* margin-top: 25px; */
  margin-bottom: 10px;
  z-index: 2;
}

@media all and (min-width: 1536px){

  .pro_leaderboard_subbox:hover {
    background: #27262c;
    border-radius: 12px;
    padding: 30px 28px;
    /* min-height: 606px; */
    min-height: 100%;
    z-index: 1;
  }

  .pro_leaderboard_subbox span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
    z-index: 1;
  }

  .pro_leaderboard_subbox span:after {
    position: absolute;
    opacity: 0;
    top: 0;
    right: -25px;
    transition: 0.5s;
    z-index: 1;
  }

  .pro_leaderboard_subbox:hover span {
    padding-right: 25px;
    z-index: 1;
  }

  .pro_leaderboard_subbox:hover span:after {
    opacity: 1;
    right: 0;
    z-index: 1;
  }
}


.swap-form {
  position: relative;
    min-height: 2000px;
    margin-bottom: 20px;
    padding: 24px;
    background: var(--form-background);
    border: 1px solid var(--transparent-line);
    border-radius: 19px;
 }

.pro_leaderboard_statsbox {
  border-radius: 12px;
  padding: 30px 28px;
  /* min-height: 606px; */
  /* min-height: 100%; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 2;
}

.pro_leaderboard_subbox {
  background: #27262c;
  border-radius: 12px;
  padding: 30px 28px;
  /* min-height: 606px; */
  min-height: 100%;
  transition: all 0.5s;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 1;
}




.wallet-address {
  background-color: white;
  opacity: 0.4;
  color: black;
  /* padding: 4px 8px; */
  text-align: center;
  border-radius: 5px;
}

.pro-text p {
  /* color: #8bfcb8; */
  background: linear-gradient(150deg, #00ffcf 0%, #3d5dff 33%, #ff73e1 66%, #ffd148 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;
  z-index: 1;
}
.pro-text h1 {
  color: transparent;
  background: linear-gradient(150deg, #00ffcf 0%, #3d5dff 33%, #ff73e1 66%, #ffd148 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 120px;
  line-height: 100px;
  z-index: 1;
}

.pro_leaderboard_statsbox h2 {
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  z-index: 2;
}

.pro_leaderboard_statsbox h4 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  z-index: 2;
}

.pro-subtext h2 {
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 72px;
  line-height: 50px;
  border-radius: 50%;
  text-shadow:2px 2px 20px #6950DD;
  z-index: 1;
}

.pro-subtext h3 {
  /* color: #ffffff;  */
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  border-radius: 50%;
  text-shadow:2px 2px 20px #6950DD;
  z-index: 1;
}

.pro-subtext h4 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 50px;
  border-radius: 50%;
  z-index: 1;
}

.pro-subtext h6 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  border-radius: 50%;
  /* text-shadow:0px 0px 10px #6950DD; */
  z-index: 1;
}

@media all and (max-width: 640px){
  .pro-subtext h4 {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
  }
  .pro-subtext h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
  }
  .pro-subtext h2 {
    font-weight: 500;
    font-size: 40px;
    line-height: 30px;
  }
  .pro-text h1 {
    font-weight: 500;
    font-size: 50px;
    line-height: 40px;
  }
}
@media all and (min-width: 640px) and (max-width: 1024px){
  .pro-subtext h4 {
    font-weight: 500;
    font-size: 28px;
    line-height: 50px;
  }
  .pro-subtext h3 {
    font-weight: 500;
    font-size: 26px;
    line-height: 50px;
  }
  .pro-subtext h2 {
    font-weight: 500;
    font-size: 60px;
    line-height: 50px;
  }
  .pro-text h1 {
    font-weight: 500;
    font-size: 75px;
    line-height: 60px;
  }
}

.pro-subtext p {
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;
  border-radius: 50%;
  text-shadow:1px 1px 10px #6950DD;
  z-index: 1;
}

.pro-subtext h5 {
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  z-index: 1;
}

.pro-button-text{
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  border-radius: 50%;
  z-index: 1;
  /* text-shadow:1px 1px 10px #6950DD */
}

.sponsorLogo {
  max-width:230px;
  max-height:95px;
  width: auto;
  height: auto;
}

.sponsorDetails p {
  z-index: 1;
}

.Dropdown-option {
  color:white !important;
  /* background-color: rgba(0,0,0,.2) !important; */
}

.Dropdown-option.is-selected {
  background-color: #292d3c !important;
}

.Dropdown-option:hover {
  background-color: #292d3c !important;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fade-in { animation: fadeIn 5s; }